// prop-types is a library for typechecking of props
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBButton from "components/PBButton";
import Funciones from "widgets/Function";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

function Transaction(props) {
  const { movimiento } = props;
  const { _id, creationDate, approvalDate, comments, paymentMethod, url, amountUsdt, status } =
    movimiento;
  const { NumSepCom } = Funciones;
  const [pago, setPagar] = useState(false);
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    if (status === "100") {
      if (amountUsdt > 0) {
        setColor("success");
        setIcon("expand_less");
      } else {
        setColor("error");
        setIcon("expand_more");
      }
    } else {
      setColor("dark");
      setIcon("priority_high");
    }
  }, []);

  return (
    <>
      <PBBox key={_id} component="li" py={1} pr={2} mb={1}>
        <PBBox display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <PBBox display="flex" alignItems="center">
                <PBBox mr={2}>
                  <PBButton
                    variant="outlined"
                    color={color}
                    iconOnly
                    circular
                    onClick={() => setPagar(true)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                  </PBButton>
                </PBBox>
                <PBBox display="flex" flexDirection="column">
                  <PBTypography
                    variant="button"
                    fontWeight="medium"
                    style={{ textTransform: "uppercase" }}
                  >
                    {_id}
                  </PBTypography>
                  <PBTypography
                    variant="button"
                    fontWeight="regular"
                    style={{ textTransform: "uppercase" }}
                  >
                    {comments}
                  </PBTypography>
                </PBBox>
              </PBBox>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
              <PBTypography variant="button" color={color} fontWeight="medium" textGradient>
                {`$${NumSepCom(amountUsdt)} USDT`}
              </PBTypography>
            </Grid>
          </Grid>
        </PBBox>
      </PBBox>

      <Modal
        id="qr-pago"
        open={pago}
        onClose={() => setPagar(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 0 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 12, fontWeight: "bold", textTransform: "uppercase" }}
              textAling="center"
            >
              Movimiento:
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 15 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 15, textTransform: "uppercase" }}
              textAling="center"
            >
              {_id}
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 0 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 12, fontWeight: "bold", textTransform: "uppercase" }}
              textAling="center"
            >
              Concepto:
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 15 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 15, textTransform: "uppercase" }}
              textAling="center"
            >
              {comments}
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 0 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 12, fontWeight: "bold", textTransform: "uppercase" }}
              textAling="center"
            >
              Monto:
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 15 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 15, textTransform: "uppercase" }}
              textAling="center"
            >
              ${amountUsdt} USDT
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 0 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 12, fontWeight: "bold", textTransform: "uppercase" }}
              textAling="center"
            >
              Fecha de solicitud:
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 15 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 15, textTransform: "uppercase" }}
              textAling="center"
            >
              {creationDate}
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 0 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 12, fontWeight: "bold", textTransform: "uppercase" }}
              textAling="center"
            >
              Fecha de aprobación:
            </PBTypography>
          </PBBox>
          <PBBox mb={1} display="flex" justifyContent="left" style={{ marginBottom: 15 }}>
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 15, textTransform: "uppercase" }}
              textAling="center"
            >
              {status === "100" ? approvalDate : "Sin aprobar"}
            </PBTypography>
          </PBBox>
          {paymentMethod === "20006" ? (
            <PBBox>
              <PBButton variant="text" color="primary" href={url} target="_blank" fullWidth>
                Ver ticket en coinpayments
              </PBButton>
            </PBBox>
          ) : null}
        </Card>
      </Modal>
    </>
  );
}

export default Transaction;

// React
import React, { useEffect } from "react";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import Fetchs from "widgets/Fetchs";
import Codigos from "generales/codigos";
import manejoErrores from "generales/errores";

// Powerups Components React contexts
import { useMaterialUIController } from "context";

const datosEmpresa = () => {
  const [controller] = useMaterialUIController();
  const { account } = controller;
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [lada, setLada] = React.useState({ pais: "México", codigo: "+52" });
  const [telefono, setTelefono] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [newpassword, setNewpassword] = React.useState("");
  const { DatUs, ActUs, CCUs } = Fetchs;

  const buscarDatos = () => {
    const resultado = DatUs(account);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) {
          const row = value;
          setUsuario(row.nickname);
          setNombre(row.name);
          setApellido(row.lastname);
          setCorreo(row.email);
          setTelefono(row.phone);
          const jsonTemp = { pais: row.address.country, codigo: row.lada };
          setLada(jsonTemp);
        } else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    buscarDatos();
  }, []);

  const ActualizarUsuario = () => {
    const resultado = ActUs(account, nombre, apellido, correo, lada.codigo, telefono, lada.pais);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) {
          alert("Actualizado correctamente");
        } else {
          alert(manejoErrores(value));
        }
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const CambiarContrasenia = () => {
    const resultado = CCUs(account, password, newpassword);
    resultado
      .then((value) => {
        if (value.token !== undefined && value.statusCode === undefined) {
          alert("Su contraseña se actualizo correctamente");
        } else {
          alert("Corre incorrecto");
        }
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox id="arriba">
        <PBBox mb={1}>
          <PBInput type="text" label="Usuario" variant="standard" value={usuario} fullWidth />
        </PBBox>
        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Nombre"
            variant="standard"
            value={nombre}
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
          />
        </PBBox>
        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Apellidos"
            variant="standard"
            value={apellido}
            fullWidth
            onChange={(e) => setApellido(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <Autocomplete
            id="combo-box-demo"
            value={lada}
            onChange={(event, newValue) => {
              setLada(newValue);
            }}
            options={Codigos}
            getOptionLabel={(option) => `${option.pais} (${option.codigo})`}
            renderInput={(params) => <TextField {...params} label="Pais" variant="standard" />}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="number"
            label="Teléfono"
            variant="standard"
            value={telefono}
            fullWidth
            onChange={(e) => setTelefono(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="email"
            label="Email"
            variant="standard"
            value={correo}
            fullWidth
            onChange={(e) => setCorreo(e.target.value)}
          />
        </PBBox>
        <PBBox mt={4} mb={1}>
          <PBButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() => ActualizarUsuario()}
          >
            Guardar cambios
          </PBButton>
        </PBBox>
        <PBBox mt={6} mb={1}>
          <PBInput
            type="password"
            label="Contraseña actual"
            variant="standard"
            value={password}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
        </PBBox>
        <PBBox mb={1}>
          <PBInput
            type="password"
            label="Nueva contraseña"
            variant="standard"
            value={newpassword}
            fullWidth
            onChange={(e) => setNewpassword(e.target.value)}
          />
        </PBBox>
        <PBBox mt={4} mb={1}>
          <PBButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() => CambiarContrasenia()}
          >
            Cambiar contraseña
          </PBButton>
        </PBBox>
      </PBBox>
    </DashboardLayout>
  );
};

export default datosEmpresa;

// Powerups React widgets components
import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import PBInput from "components/PBInput";

// Powerups React widgets components
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";
import Funciones from "widgets/Function";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DataUsers from "./nfts";
import DataWallet from "./billing";
import AgregarUsuario from "./agregar";

const columns = [
  { Header: "Id", accessor: "id", width: "15%", align: "left" },
  { Header: "Nombre", accessor: "nombre", align: "left" },
  { Header: "Wallet", accessor: "wallet", align: "left" },
  { Header: "Telefono", accessor: "telefono", width: "10%", align: "left" },
  { Header: "Correo", accessor: "correo", width: "10%", align: "left" },
  { Header: "Nfts", accessor: "oper", width: "5%", align: "left" },
];

function Tables() {
  const [rows, setRows] = useState([]);
  const [notificacion, setNotificacion] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [user, setUser] = useState({});
  const [nfts, setNfts] = useState([]);
  const { BusUs, BNftUs } = Fetchs;
  const { NumSepCom, Round } = Funciones;

  const leerNFTs = async (us) => {
    try {
      let items = [];
      const resultado = BNftUs(us["_id"]);
      await resultado
        .then((value) => {
          if (value.statusCode === undefined) items = value;
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
      setUser(us);
      setNfts(items);
      setNotificacion(true);
    } catch (error) {
      alert(manejoErrores(error));
    }
  };

  const verWallet = async (us) => {
    try {
      setUser(us["_id"]);
      setWallet(true);
    } catch (error) {
      alert(manejoErrores(error));
    }
  };

  const Tabla = (rowsTemp) => {
    const array = [];
    rowsTemp.forEach((e) =>
      array.push({
        id: e["_id"],
        nombre: `${e["name"]} ${e["lastname"]}`,
        telefono: e.phone,
        correo: e.email,
        wallet: `$ ${NumSepCom(Round(e.wallet))} USDT`,
        oper: (
          <PBBox ml={-1}>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={() => leerNFTs(e)}
            >
              NFTS
            </PBButton>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
                marginLeft: 10,
              }}
              onClick={() => verWallet(e)}
            >
              Wallet
            </PBButton>
          </PBBox>
        ),
      })
    );
    setRows(array);
  };

  const Buscar = () => {
    let parametros = "limit=500";
    if (nombre !== "") parametros += `&name=${nombre}`;
    if (correo !== "") parametros += `&email=${correo}`;
    const resultado = BusUs(parametros);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) Tabla(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        console.error(error);
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    Buscar();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox pt={6} pb={3}>
        <Grid container spacing={1} mb={2}>
          <Grid item sm={12} md={3}>
            <PBInput
              type="text"
              label="Nombre"
              variant="standard"
              fullWidth
              onChange={(e) => setNombre(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <PBInput
              type="email"
              label="Correo"
              variant="standard"
              fullWidth
              onChange={(e) => setCorreo(e.target.value)}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <PBButton
              fullWidth
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={Buscar}
            >
              Buscar
            </PBButton>
          </Grid>
          <Grid item sm={12} md={3}>
            <PBButton
              fullWidth
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={() => setAddUser(true)}
            >
              Agregar
            </PBButton>
          </Grid>
        </Grid>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />

        <Modal
          id="Comprobacion.inversion"
          open={notificacion}
          onClose={() => setNotificacion(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 0,
              minWidth: "60%",
              maxWidth: "80%",
              maxHeight: "85%",
              overflow: "scroll",
            }}
          >
            <DataUsers arrayNfts={nfts} user={user} />
          </Card>
        </Modal>

        <Modal
          id="Comprobacion.inversion"
          open={wallet}
          onClose={() => setWallet(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 0,
              minWidth: "60%",
              maxWidth: "80%",
              maxHeight: "85%",
              overflow: "scroll",
            }}
          >
            <DataWallet account={user} />
          </Card>
        </Modal>

        <Modal
          id="Comprobacion.inversion"
          open={addUser}
          onClose={() => setAddUser(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              width: "40%",
              height: "auto",
              overflow: "scroll",
            }}
          >
            <AgregarUsuario />
          </Card>
        </Modal>
      </PBBox>
    </DashboardLayout>
  );
}

export default Tables;

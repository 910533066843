// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBBadge from "components/PBBadge";
import PBButton from "components/PBButton";

// Powerups React widgets components
import Details from "layouts/detailsComissions";

// Powerups React widgets components
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import Fuction from "widgets/Function";
import manejoErrores from "generales/errores";

// Powerups Components React context
import { useMaterialUIController } from "context";

const columns = [
  { Header: "Monto total", accessor: "total", width: "15%", align: "left" },
  { Header: "Mensualidad", accessor: "amount", width: "10%", align: "left" },
  { Header: "Inicio", accessor: "startDate", width: "15%", align: "left" },
  { Header: "Fin", accessor: "endDate", width: "5%", align: "left" },
  { Header: "Avance", accessor: "advance", width: "10%", align: "left" },
  { Header: "Estado", accessor: "status", width: "5%", align: "left" },
  { Header: "Operaciones", accessor: "oper", width: "5%", align: "left" },
];

function Tables() {
  const [rows, setRows] = React.useState([]);
  const [controller] = useMaterialUIController();
  const [notificacion, setNotificacion] = React.useState(false);
  const [producto, setProducto] = React.useState({});
  const { sidenavColor, nft } = controller;
  const { contract, id } = nft;
  const { BCnft } = Fetchs;
  const { Round, RTxt } = Fuction;

  const Tabla = (rowsTemp) => {
    const array = [];
    for (let i = 0; i < rowsTemp.length; i += 1) {
      const startDate = moment.utc(rowsTemp[i].startDate).format("YYYY-MM-DD");
      const endDate = moment.utc(rowsTemp[i].endDate).format("YYYY-MM-DD");
      const end = moment.utc();
      let unidades = "";
      let avance = 0;

      if (rowsTemp[i].Settings[0].period.unit === "meses") {
        unidades = "months";
      } else if (rowsTemp[i].Settings[0].period.unit === "años") {
        unidades = "years";
      } else if (rowsTemp[i].Settings[0].period.unit === "dias") {
        unidades = "days";
      }

      const diff = end.diff(startDate, unidades, true);

      let colores = { color: "warning", texto: "Sin iniciar" };

      if (rowsTemp[i].status === "100") {
        colores = { color: "success", texto: "Activo" };
        avance = (diff / parseInt(rowsTemp[i].Settings[0].period.amount, 10)) * 100;
      } else if (rowsTemp[i].status === "-1") {
        colores = { color: "error", texto: "Finalizado" };
        avance = 100;
      }

      array.push({
        id: rowsTemp[i]["_id"],
        total: `$${Round(rowsTemp[i].total)} USDT`,
        financialProduct: rowsTemp[i].Settings[0].name,
        amount: `$${Round(rowsTemp[i].amount)} USDT`,
        interest: `${rowsTemp[i].Settings[0].interest}%`,
        startDate: String(startDate),
        endDate: String(endDate),
        advance: `${Round(avance)}%`,
        status: (
          <PBBox ml={-1}>
            <PBBadge
              badgeContent={colores.texto}
              color={colores.color}
              variant="gradient"
              size="md"
            />
          </PBBox>
        ),
        oper: (
          <PBBox ml={-1}>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={() => {
                setNotificacion(true);
                setProducto(rowsTemp[i]);
              }}
            >
              Ver detalles
            </PBButton>
          </PBBox>
        ),
      });
    }
    setRows(array);
  };

  const Buscar = () => {
    const resultado = BCnft(contract, id);
    resultado
      .then((value) => {
        console.log(value);
        if (value.statusCode === undefined) Tabla(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        console.error(error);
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    Buscar();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox display="flex" flexDirection="row" sx={{ marginTop: 5 }}>
        <Avatar alt={nft.name} src={nft.url} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.id, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      <PBBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <PBBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <PBTypography variant="h6" color="white">
                  Comisiones
                </PBTypography>
              </PBBox>
              <PBBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </PBBox>
            </Card>
          </Grid>
        </Grid>

        <Modal
          id="Comprobacion.inversion"
          open={notificacion}
          onClose={() => setNotificacion(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: "60%",
            }}
          >
            <Details producto={producto} nft={nft} />
          </Card>
        </Modal>
      </PBBox>
    </DashboardLayout>
  );
}

export default Tables;

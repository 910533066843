// React
import React from "react";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";

// Images, logos, icons
import Logo from "assets/logos/edbit.svg";

function Cadena() {
  const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let resultado = "";
  const longitud = 10;

  for (let i = 0; i < longitud; i += 1) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    resultado += caracteres.charAt(indiceAleatorio);
  }

  return resultado;
}

function Cover() {
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [contraseña, setContraseña] = React.useState("");
  const [telefono, setTelefono] = React.useState(0);
  const { InserUs } = Fetchs;

  const InsertarUsuario = () => {
    const resultado = InserUs(
      `User-${Cadena()}`,
      nombre,
      apellido,
      correo,
      "+52",
      telefono,
      0,
      "México",
      "cliente",
      [],
      new Date(),
      [],
      [],
      "100",
      contraseña
    );
    resultado
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        console.error(error);
        alert(manejoErrores(error));
      });
  };

  const Verificar = () => {
    InsertarUsuario();
  };

  return (
    <PBBox m={0} p={0}>
      <PBBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <PBBox component="img" src={Logo} alt="Brand" width="5rem" />
        <PBTypography variant="h4" fontWeight="medium" color="white" mt={0}>
          ¡Bienvenido a la familia!
        </PBTypography>
      </PBBox>
      <PBBox pt={2} pb={3} px={3} id="arriba" sx={{ overflow: "scroll" }}>
        <PBBox component="form" role="form">
          {/* Nombre */}
          <PBBox mb={1}>
            <PBInput
              type="text"
              label="Nombre"
              variant="standard"
              fullWidth
              onChange={(e) => setNombre(e.target.value)}
            />
          </PBBox>

          {/* Apellido */}
          <PBBox mb={1}>
            <PBInput
              type="text"
              label="Apellido"
              variant="standard"
              fullWidth
              onChange={(e) => setApellido(e.target.value)}
            />
          </PBBox>

          {/* TELÉFONO */}
          <PBBox mb={1}>
            <PBInput
              type="number"
              label="Teléfono"
              variant="standard"
              fullWidth
              onChange={(e) => setTelefono(e.target.value)}
            />
          </PBBox>

          {/* Email */}
          <PBBox mb={1}>
            <PBInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              onChange={(e) => setCorreo(e.target.value)}
            />
          </PBBox>

          {/* Contraseña */}
          <PBBox mb={1}>
            <PBInput
              type="password"
              label="Contraseña"
              variant="standard"
              fullWidth
              onChange={(e) => setContraseña(e.target.value)}
            />
          </PBBox>

          <PBBox mt={4} mb={1}>
            <PBButton variant="gradient" color="primary" fullWidth onClick={() => Verificar()}>
              Registrar
            </PBButton>
          </PBBox>
        </PBBox>
      </PBBox>
    </PBBox>
  );
}

export default Cover;

import React from "react";

// react-router components
import { useNavigate } from "react-router-dom";

// @mui material components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import LogoPolygon from "assets/logos/polygon.png";
import Table from "layouts/table";
import Functions from "widgets/Function";
import { BsImageFill, BsFillFileEarmarkCodeFill } from "react-icons/bs";
import { FaWallet, FaUsers } from "react-icons/fa";
import { RiFileAddFill } from "react-icons/ri";
import { MdAttachMoney } from "react-icons/md";

// Powerups Components React context
import { useMaterialUIController, setNft } from "context";

export default function State({ nfts }) {
  const { RTxt } = Functions;
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const VerContratos = (nft) => {
    setNft(dispatch, nft);
    navigate("/asociarte");
  };

  const VerComisiones = (nft) => {
    setNft(dispatch, nft);
    navigate("/tabla-comisiones");
  };

  const VerWallet = (nft) => {
    setNft(dispatch, nft);
    navigate("/billetera-nft");
  };

  const VerCustomers = (nft) => {
    setNft(dispatch, nft);
    navigate("/cuentahabientes");
  };

  return (
    <PBBox display="flex" flexDirection="column" alignItems="center">
      {nfts.length > 0 ? (
        <div style={{ width: "100%" }}>
          {nfts.map((nft) => (
            <Accordion key={`${nft.contract}/${nft.id}`} display="flex" flexDirection="column">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <PBBox display="flex" flexDirection="row">
                  <Avatar
                    alt={nft.name}
                    src={nft.url}
                    variant="rounded"
                    sx={{ width: 120, height: 120 }}
                  />
                  <PBBox>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "left",
                        fontSize: 20,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      {nft.name}
                    </PBTypography>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      ID TOKEN: {RTxt(nft.id, 4, 4)}
                    </PBTypography>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      RED: {nft.blockchain}
                    </PBTypography>
                  </PBBox>
                </PBBox>
              </AccordionSummary>
              <AccordionDetails>
                <Divider variant="middle" />
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    flexDirection: "row",
                  }}
                >
                  {nft.permisos[2] === "1" ? (
                    <Grid item xs={4} md={2}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => VerWallet(nft)}
                      >
                        <FaWallet sx={{ fontSize: 14 }} />
                        <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                          Billetera
                        </PBTypography>
                      </IconButton>
                    </Grid>
                  ) : null}
                  {nft.permisos[1] === "1" ? (
                    <Grid item xs={4} md={2}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => VerComisiones(nft)}
                      >
                        <MdAttachMoney sx={{ fontSize: 14 }} />
                        <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                          Comiciones
                        </PBTypography>
                      </IconButton>
                    </Grid>
                  ) : null}
                  {nft.permisos[3] === "1" ? (
                    <Grid item xs={4} md={2}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => VerCustomers(nft)}
                      >
                        <FaUsers sx={{ fontSize: 14 }} />
                        <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                          Cuentahabientes
                        </PBTypography>
                      </IconButton>
                    </Grid>
                  ) : null}
                  {nft.permisos[0] === "1" ? (
                    <Grid item xs={4} md={2}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => VerContratos(nft)}
                      >
                        <RiFileAddFill sx={{ fontSize: 14 }} />
                        <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                          Asociarte
                        </PBTypography>
                      </IconButton>
                    </Grid>
                  ) : null}
                  <Grid item xs={4} md={2}>
                    <IconButton
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      href={`https://opensea.io/assets/matic/${nft.contract}/${nft.id}`}
                      target="_blank"
                    >
                      <BsImageFill sx={{ fontSize: 14 }} />
                      <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                        Ver NFT
                      </PBTypography>
                    </IconButton>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <IconButton
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      href={`https://polygonscan.com/address/${nft.contract}`}
                      target="_blank"
                    >
                      <BsFillFileEarmarkCodeFill sx={{ fontSize: 14 }} />
                      <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                        Ver contrato
                      </PBTypography>
                    </IconButton>
                  </Grid>
                </Grid>
                <Table datos={nft} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <PBBox display="flex" flexDirection="column" alignItems="center">
          <PBBox component="img" src={LogoPolygon} alt="Brand" width="5rem" />
          <PBTypography
            display="flex"
            style={{ textAlign: "center", fontSize: 12, fontWeight: "bold" }}
            color="dark"
          >
            No tiene ningun nft relacionado con EDbit.
          </PBTypography>
        </PBBox>
      )}
    </PBBox>
  );
}

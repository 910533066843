import React, { useState } from "react";
import { useMetaMask } from "metamask-react";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import PBTypography from "components/PBTypography";
import Fetchs from "widgets/Fetchs";

import LogoMetamask from "assets/logos/metamask.png";
import LogoPolygon from "assets/logos/polygon.png";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PBInput from "components/PBInput";

const contratos = [
  {
    key: "Green Great Bulls",
    value: "0x50f263c89c307da230398a4fb96a531d6994340c",
  },
  {
    key: "EDbit Fundadores",
    value: "0x389a2ce9fbcdc101c7635fd2a83cd032c326348f",
  },
  {
    key: "EDbit BLACK México",
    value: "0xa0deea9bff08ae784cd7f4b3f0cd0b583403859a",
  },
  {
    key: "Sucursales EDbit México",
    value: "0x4c2113ecb4798cb622ec23851f92a606a25e44ea",
  },
];

export default function State({ idUser, salir }) {
  const { status, connect, account, chainId } = useMetaMask();
  const [contrato, setContrato] = useState(contratos[0].value);
  const [idToken, setIdToken] = useState("");
  const { RNft } = Fetchs;

  const Reclamar = () => {
    const resultado = RNft(account, idUser, contrato, idToken);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) alert("El NFT se vinculo correctamente a tu cuenta");
        else alert(value.message);
      })
      .catch();
  };

  if (status === "initializing")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="center">
        <PBBox component="img" src={LogoMetamask} alt="Brand" width="5rem" />
        <PBTypography
          display="flex"
          style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          color="dark"
          mb={2}
        >
          Sincronizando con MetaMask, conectando...
        </PBTypography>
        <PBButton variant="gradient" color="primary" onClick={connect}>
          Actualizar
        </PBButton>
        <PBButton sx={{ mt: 1 }} variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
  if (status === "unavailable")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="center">
        <PBBox component="img" src={LogoMetamask} alt="Brand" width="5rem" />
        <PBTypography
          display="flex"
          style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          color="dark"
          mb={2}
        >
          Para poder acceder es necesario contar con MetaMask instalado en tu navegador
        </PBTypography>
        <PBButton variant="gradient" color="primary" href="https://metamask.io/" target="_blank">
          Instalar MetaMask
        </PBButton>
        <PBButton variant="text" color="primary" onClick={connect}>
          Actualizar
        </PBButton>
        <PBButton sx={{ mt: 1 }} variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
  if (status === "notConnected")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="center">
        <PBBox component="img" src={LogoMetamask} alt="Brand" width="5rem" />
        <PBButton variant="gradient" color="primary" onClick={connect}>
          Conectar a metamask
        </PBButton>
        <PBButton sx={{ mt: 1 }} variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
  if (status === "connecting")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="center">
        <PBBox component="img" src={LogoMetamask} alt="Brand" width="5rem" />
        <PBTypography
          display="flex"
          style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          color="dark"
          mb={2}
        >
          Conectando...
        </PBTypography>
        <PBButton sx={{ mt: 1 }} variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
  if (status === "connected" && chainId !== "0x89")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="center">
        <PBBox component="img" src={LogoPolygon} alt="Brand" width="5rem" />
        <PBTypography
          display="flex"
          style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          color="dark"
          mb={2}
        >
          La wallet conectada no esta en la red de Polygon
        </PBTypography>
        <PBButton sx={{ mt: 1 }} variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
  if (status === "connected")
    return (
      <PBBox display="flex" flexDirection="column" alignItems="left">
        <PBTypography
          display="flex"
          style={{ textAlign: "left", fontSize: 15, fontWeight: "bold" }}
          color="dark"
          mb={0}
        >
          Wallet:
        </PBTypography>
        <PBTypography
          display="flex"
          style={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
          color="dark"
          mb={2}
        >
          {account}
        </PBTypography>
        <FormControl fullWidth sx={{ mb: 1, minWidth: 120 }}>
          <InputLabel htmlFor="grouped-native-select">Selecciona contrato</InputLabel>
          <Select
            native
            id="grouped-native-select"
            value={contrato}
            fullWidth
            onChange={(e) => {
              setContrato(e.target.value);
            }}
          >
            {contratos.map((e) => (
              <option value={e.value}> {e.key} </option>
            ))}
          </Select>
        </FormControl>
        <PBInput
          type="text"
          label="Id de token"
          variant="outlined"
          fullWidth
          value={idToken}
          sx={{ mb: 2 }}
          onChange={(e) => setIdToken(e.target.value)}
        />
        <PBButton sx={{ mb: 1 }} variant="gradient" color="primary" onClick={Reclamar}>
          Agregar
        </PBButton>
        <PBButton variant="gradient" color="primary" onClick={() => salir(true)}>
          Salir
        </PBButton>
      </PBBox>
    );
}

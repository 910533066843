// @mui material components
import Card from "@mui/material/Card";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
// import PBButton from "components/PBButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";

function Transactions(props) {
  const { array, mensaje, tipo } = props;

  return (
    <Card sx={{ height: "100%" }}>
      <PBBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
        pt={3}
        px={2}
      >
        <PBTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {`Tus transacciones ${tipo}`}
        </PBTypography>
        <PBTypography variant="button" color="text" fontWeight="regular">
          {mensaje}
        </PBTypography>
      </PBBox>
      <PBBox pt={1} pb={2} px={2}>
        <PBBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {array.map((row) => (
            <Transaction movimiento={row} />
          ))}
        </PBBox>
      </PBBox>
    </Card>
  );
}

export default Transactions;

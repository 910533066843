import React, { useEffect, useState } from "react";

// @mui material components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PBButton from "components/PBButton";
import { Card, Modal } from "@mui/material";

// Powerups React components
import { IoIosDocument } from "react-icons/io";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import LogoPolygon from "assets/logos/polygon.png";
import Functions from "widgets/Function";
import Table from "../table";
import AddNft from "../addNft";
import AddContrac from "../addProduct";

export default function State({ arrayNfts, user }) {
  const { RTxt } = Functions;
  const [nfts, setNfts] = useState([]);
  const [open, setOpen] = useState(false);
  const [contrato, setContrato] = useState(false);
  const [nftData, setNftData] = useState(null);

  useEffect(() => {
    const array = [];
    arrayNfts.forEach((e) => {
      array.push({
        db: e["_id"],
        id: e.nftId,
        blockchain: e.blockchain,
        name: e.name,
        description: e.description,
        contract: e.contract,
        url: e.image,
      });
    });
    setNfts(array);
  }, []);

  const agregar = () => {
    setOpen(true);
  };

  const agregarContrato = (data) => {
    setNftData(data);
    setContrato(true);
  };

  return (
    <PBBox display="flex" flexDirection="column" alignItems="center">
      <PBBox>
        <PBButton
          fullWidth
          variant="gradient"
          color="primary"
          style={{
            fontSize: 10,
            padding: 10,
            marginBottom: 50,
          }}
          onClick={agregar}
        >
          Agregar NFT
        </PBButton>
      </PBBox>
      {nfts.length > 0 ? (
        <div style={{ width: "100%" }}>
          {nfts.map((nft) => (
            <Accordion key={`${nft.contract}/${nft.id}`} display="flex" flexDirection="column">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <PBBox display="flex" flexDirection="row">
                  <Avatar
                    alt={nft.name}
                    src={nft.url}
                    variant="rounded"
                    sx={{ width: 120, height: 120 }}
                  />
                  <PBBox>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "left",
                        fontSize: 20,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      {nft.name}
                    </PBTypography>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      Id DB: {nft.db}
                    </PBTypography>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      ID TOKEN: {RTxt(nft.id, 4, 4)}
                    </PBTypography>
                    <PBTypography
                      display="flex"
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                      color="dark"
                    >
                      RED: {nft.blockchain}
                    </PBTypography>
                  </PBBox>
                </PBBox>
              </AccordionSummary>
              <AccordionDetails>
                <Divider variant="middle" />
                {nft.contract === "GOLD" && (
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Grid item xs={4} md={2}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => agregarContrato(nft)}
                      >
                        <IoIosDocument sx={{ fontSize: 14 }} />
                        <PBTypography style={{ textAlign: "center", fontSize: 12 }} color="dark">
                          Agregar contrato
                        </PBTypography>
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <Table datos={nft} user={user} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <PBBox display="flex" flexDirection="column" alignItems="center">
          <PBBox component="img" src={LogoPolygon} alt="Brand" width="5rem" />
          <PBTypography
            display="flex"
            style={{ textAlign: "center", fontSize: 12, fontWeight: "bold" }}
            color="dark"
          >
            No tiene ningun nft relacionado con EDbit.
          </PBTypography>
        </PBBox>
      )}

      <Modal
        id="Comprobacion.inversion"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Card
          id="contenedor"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 0,
            width: "20%",
            height: "auto",
            overflow: "scroll",
          }}
        >
          <AddNft usuario={user} />
        </Card>
      </Modal>

      <Modal
        id="Comprobacion.inversion"
        open={contrato}
        onClose={() => setContrato(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Card
          id="contenedor"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 0,
            width: "20%",
            height: "auto",
            overflow: "scroll",
          }}
        >
          <AddContrac usuario={user} nft={nftData} />
        </Card>
      </Modal>
    </PBBox>
  );
}

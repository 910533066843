// React
import React, { useEffect, useState } from "react";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";

function Cover({ usuario, nft }) {
  const { IPGold } = Fetchs;
  const [inicio, setInicio] = useState(new Date().toISOString().split("T")[0]);
  const [fin, setFin] = useState("");
  const [monto, setMonto] = useState(0);
  const [meses, setMeses] = useState(0);

  useEffect(() => {
    const fecha = new Date(inicio);
    let fechaFinal = new Date();
    if (fecha.getMonth() < 5) fechaFinal = new Date(fecha.getFullYear() + 2, 6, 1);
    else if (fecha.getMonth() > 10) fechaFinal = new Date(fecha.getFullYear() + 3, 6, 1);
    else fechaFinal = new Date(fecha.getFullYear() + 3, 0, 1);
    const diferenciaAnios = fechaFinal.getFullYear() - fecha.getFullYear();
    const diferenciaMeses = diferenciaAnios * 12 + (fechaFinal.getMonth() - fecha.getMonth());
    setMeses(diferenciaMeses - 1);
    setFin(fechaFinal.toISOString().split("T")[0]);
  }, [inicio]);

  const InsertarContrato = () => {
    const resultado = IPGold(
      "GOLD",
      nft.id,
      `INV-GOLD-${meses}`,
      monto,
      inicio,
      fin,
      usuario["_id"]
    );
    resultado
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        console.error(error);
        alert(manejoErrores(error));
      });
  };

  const Verificar = () => {
    InsertarContrato();
  };

  return (
    <PBBox m={0} p={0}>
      <PBBox pt={2} pb={3} px={3} id="arriba" sx={{ overflow: "scroll" }}>
        <PBBox component="form" role="form">
          {/* Monto */}
          <PBBox mb={1}>
            <PBInput
              type="number"
              label="Monto"
              variant="standard"
              fullWidth
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
            />
          </PBBox>

          {/* Inicio */}
          <PBBox mb={1}>
            <PBInput
              type="date"
              label="Fecha de inicio"
              variant="standard"
              fullWidth
              value={inicio}
              onChange={(e) => setInicio(e.target.value)}
            />
          </PBBox>

          {/* Final */}
          <PBBox mb={1}>
            <PBInput
              type="date"
              label="Fecha final"
              variant="standard"
              fullWidth
              value={fin}
              onChange={(e) => setFin(e.target.value)}
            />
          </PBBox>

          {/* Meses */}
          <PBBox mb={1}>
            <PBInput
              type="number"
              label="Meses"
              variant="standard"
              fullWidth
              value={meses}
              onChange={(e) => setMeses(e.target.value)}
            />
          </PBBox>

          <PBBox mt={4} mb={1}>
            <PBButton variant="gradient" color="primary" fullWidth onClick={() => Verificar()}>
              Registrar
            </PBButton>
          </PBBox>
        </PBBox>
      </PBBox>
    </PBBox>
  );
}

export default Cover;

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import PBInput from "components/PBInput";
import PBTypography from "components/PBTypography";

// Powerups Components React examples
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DefaultInfoCard from "widgets/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Funciones from "widgets/Function";
import Fetchs from "widgets/Fetchs";

// ICONS
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// Powerups Components React context
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";

function Billing() {
  const [controller] = useMaterialUIController();
  const { NumSepCom } = Funciones;
  const { sidenavColor, login, account } = controller;
  const { TransD, AhTotal, BusUs } = Fetchs;
  const [usuario, setUsuario] = useState("");
  const [datosUsuario, setDatosUsuario] = useState({});
  const [monto, setMonto] = useState(0);
  const [notificacion, setNotificacion] = useState(false);
  const [ahorro, setAhorro] = useState({ total: 0, fecha: "00/00/00" });
  const minimo = 10;

  const recargar = () => {
    setMonto(0);
    setUsuario("");
    const resAhorro = AhTotal(account.idUsuario);
    resAhorro
      .then((value) => {
        if (value.mensaje === null) {
          setAhorro({ total: 0, fecha: "00/00/00" });
        } else setAhorro(value.mensaje);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (login) {
      recargar();
    }
  }, []);

  const TransferirDinero = () => {
    setNotificacion(false);
    const res = TransD(usuario, account.idUsuario, monto);
    res
      .then((value) => {
        alert(value.mensaje);
        recargar();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const BuscarUsuario = () => {
    const res = BusUs(usuario);
    res
      .then((value) => {
        setDatosUsuario(value.mensaje);
        setNotificacion(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Verficar = () => {
    if (monto >= minimo) BuscarUsuario();
    else alert(`El monto debe ser mayor a $${minimo} USDT`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox mt={2} mb={7}>
        <PBBox mb={15}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PBBox mb={3}>
                <DefaultInfoCard
                  color={sidenavColor}
                  icon={<AttachMoneyIcon />}
                  title="Saldo total"
                  description="Este dinero esta en tu billetera"
                  value={`$${NumSepCom(ahorro.total)} USDT`}
                />
              </PBBox>
              <PBBox mt={4} mb={3}>
                <Card
                  style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}
                >
                  <PBBox mb={1}>
                    <PBInput
                      type="text"
                      label="Usuario"
                      variant="standard"
                      fullWidth
                      value={usuario}
                      onChange={(e) => setUsuario(e.target.value)}
                    />
                  </PBBox>
                  <PBBox mb={1}>
                    <PBInput
                      type="number"
                      label="Monto a depositar en USDT"
                      variant="standard"
                      fullWidth
                      value={monto}
                      onChange={(e) => setMonto(e.target.value)}
                    />
                  </PBBox>
                  <PBButton variant="gradient" color="primary" onClick={Verficar} fullWidth>
                    Realizar transferencia
                  </PBButton>
                </Card>
              </PBBox>
            </Grid>
          </Grid>
        </PBBox>
      </PBBox>

      <Modal
        id="holis"
        open={notificacion}
        onClose={() => setNotificacion(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
          <PBBox display="flex" justifyContent="center">
            <PBTypography
              variant="a"
              color="dark"
              style={{ fontSize: 18, marginBottom: 10 }}
              textAling="center"
            >
              {`Confirma que desas transferir $${monto} USDT a '${datosUsuario.nombre}' con nombre de usuario '${usuario}'`}
            </PBTypography>
          </PBBox>
          <PBBox>
            <PBButton variant="gradient" color="primary" onClick={TransferirDinero} fullWidth>
              Aceptar
            </PBButton>
          </PBBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
}

export default Billing;

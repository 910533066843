import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Powerups React widgets components
import Sidenav from "widgets/Sidenav";
import Configurator from "widgets/Configurator";

// Powerups Components React themes
import theme from "assets/theme";

// Powerups Components React Dark Mode themes
import themeDark from "assets/theme-dark";

// Powerups Components React routes
import routesUser from "routes";
import routesAdmin from "routesAdmin";
import routesTools from "routesTools";
import routesSucursal from "routesSucursal";

// Powerups Components React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/logos/edbit.svg";
import brandDark from "assets/images/logo-ct-dark.png";

// Powerups Components React layouts
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import DatosEmpresa from "layouts/empresa/datos";
import Perfil from "layouts/account";
import Contracts from "layouts/contracts";
import Commissions from "layouts/commissions";
import BillingNft from "layouts/billingNFT";
import AccountHolders from "layouts/accountholders";
import Admins from "layouts/admins";
import manejoErrores from "generales/errores";
import Fetchs from "widgets/Fetchs";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    login,
    account,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const nombre = "EDbit";
  const [routes, getRoutesJson] = useState([]);
  const { DatUs } = Fetchs;

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Setting page scroll to 0 when changing the route
  useEffect(async () => {
    if (account.tipo === "2") getRoutesJson(routesSucursal);
    else {
      const userData = DatUs(account);
      await userData
        .then((value) => {
          if (value.rol === "admin") getRoutesJson([...routesUser, ...routesAdmin, ...routesTools]);
          else getRoutesJson([...routesUser, ...routesTools]);
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
    }
  }, [account]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (!login) {
        return null;
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const getGenerales = () => {
    if (login) {
      return (
        <>
          <Route exact path="/datos-empresa" element={<DatosEmpresa />} />
          <Route exact path="/perfil" element={<Perfil />} />
          <Route exact path="/asociarte" element={<Contracts />} />
          <Route exact path="/tabla-comisiones" element={<Commissions />} />
          <Route exact path="/billetera-nft" element={<BillingNft />} />
          <Route exact path="/cuentahabientes" element={<AccountHolders />} />
          <Route exact path="/admin" element={<Admins />} />
        </>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Routes>
        {getRoutes(routes)}
        {getGenerales()}
        <Route exact path="/registro" element={<SignUp />} />
        <Route path="*" element={<SignIn />} />
      </Routes>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName={nombre}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {layout === "vr" && <Configurator />}
    </ThemeProvider>
  );
}

// Powerups React
import { useEffect, useState } from "react";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import Fetchs from "widgets/Fetchs";
import DataTable from "widgets/Tables/DataTable";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import PBTypography from "components/PBTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Powerups Components React context
import { useMaterialUIController } from "context";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const columns = [
  { Header: "nombre", accessor: "nombre", width: "20%", align: "left" },
  { Header: "descripción", accessor: "descripcion", width: "40%", align: "left" },
  { Header: "costo", accessor: "costo", width: "20%", align: "left" },
  { Header: "categoria", accessor: "categoria", align: "left" },
  { Header: "operaciones", accessor: "editar", align: "left" },
];

const datosEmpresa = () => {
  const [controller] = useMaterialUIController();
  const { account } = controller;
  const { IngEmp, BusEmp, BusSerEmp, IngSer, ActSer, BorSer, ActEmp } = Fetchs;
  const [id, setId] = useState("");
  const [idServicio, setIdServicio] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [nombre, setNombre] = useState("");
  const [direccion, setDireccion] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [giro, setGiro] = useState("");
  const [accion, setAccion] = useState("ingresar");
  const [rows, setRows] = useState([]);
  const [agregar, setAgregar] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [borrar, setBorrar] = useState("");
  const [servicio, setServicio] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [costo, setCosto] = useState("");
  const [categoria, setCategoria] = useState("");
  const [titulo, setTitulo] = useState("Ingresar servicio");

  const accionServicios = (tipo, array) => {
    if (tipo) {
      setServicio("");
      setDescripcion("");
      setCosto("");
      setCategoria("");
      setIdServicio("");
      setTitulo("Ingresar Servicio");
      setAgregar(true);
    } else {
      setServicio(array.nombre);
      setDescripcion(array.descripcion);
      setCosto(array.costo);
      setCategoria(array.categoria);
      setIdServicio(array.id);
      setTitulo("Actualizar Servicio");
      setAgregar(true);
    }
  };

  const buscarServicios = () => {
    const resultado = BusSerEmp(account.idUsuario);
    resultado
      .then((value) => {
        const rowsTemp = value.mensaje;
        const array = [];
        for (let i = 0; i < rowsTemp.length; i += 1) {
          array.push({
            nombre: rowsTemp[i].nombre,
            descripcion: rowsTemp[i].descripcion,
            costo: rowsTemp[i].costo,
            categoria: rowsTemp[i].categoria,
            editar: (
              <PBBox ml={-1}>
                <IconButton onClick={() => accionServicios(false, rowsTemp[i])}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setBorrar(rowsTemp[i]);
                    setConfirmar(true);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </PBBox>
            ),
          });
        }
        setRows(array);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const buscarEmpresa = () => {
    const resultado = BusEmp(account.idUsuario);
    resultado
      .then((value) => {
        if (value.mensaje === false) {
          setAccion("ingresar");
        } else {
          const row = value.mensaje;
          setAccion("guardar");
          setId(row.id);
          setRazonSocial(row.razonSocial);
          setNombre(row.nombre);
          setDireccion(row.direccion);
          setMunicipio(row.municipio);
          setEstado(row.estado);
          setPais(row.pais);
          setCorreo(row.correo);
          setTelefono(row.telefono);
          setGiro(row.giro);
          buscarServicios(row.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    buscarEmpresa();
  }, []);

  const agregarServicio = () => {
    const resultado = IngSer(id, servicio, descripcion, costo, categoria);
    resultado
      .then(() => {
        buscarServicios();
        setAgregar(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const actualizarServicio = () => {
    const resultado = ActSer(idServicio, id, servicio, descripcion, costo, categoria);
    resultado
      .then(() => {
        buscarServicios();
        setAgregar(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const borrarServicio = () => {
    const resultado = BorSer(borrar.id);
    resultado
      .then(() => {
        buscarServicios();
        setConfirmar(false);
        setAgregar(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const guardarEmpresa = () => {
    const resultado = IngEmp(
      account.idUsuario,
      account.idUsuario,
      razonSocial,
      nombre,
      direccion,
      correo,
      municipio,
      estado,
      pais,
      telefono,
      giro
    );
    resultado
      .then((value) => {
        alert(value.mensaje);
        buscarEmpresa();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const actualizarEmpresa = () => {
    const resultado = ActEmp(
      account.idUsuario,
      account.idUsuario,
      razonSocial,
      nombre,
      direccion,
      correo,
      municipio,
      estado,
      pais,
      telefono,
      giro
    );
    resultado
      .then((value) => {
        alert(value.mensaje);
        buscarEmpresa();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const accionEpresa = () => {
    if (accion === "ingresar") guardarEmpresa();
    else if (accion === "guardar") actualizarEmpresa();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={2} mb={2} mt={2} style={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PBButton
            variant="gradient"
            color="primary"
            style={{ width: 200 }}
            onClick={accionEpresa}
          >
            {accion}
          </PBButton>
        </Grid>
      </Grid>
      <Card mt={2} mb={7} style={{ padding: 30 }}>
        <PBBox>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Razón Social"
                variant="standard"
                fullWidth
                value={razonSocial}
                onChange={(e) => setRazonSocial(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Nombre comercial"
                variant="standard"
                fullWidth
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Dirrecciòn"
                variant="standard"
                fullWidth
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Municipio"
                variant="standard"
                fullWidth
                value={municipio}
                onChange={(e) => setMunicipio(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Estado"
                variant="standard"
                fullWidth
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Pais"
                variant="standard"
                fullWidth
                value={pais}
                onChange={(e) => setPais(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Correo"
                variant="standard"
                fullWidth
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Teléfono"
                variant="standard"
                fullWidth
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PBInput
                type="text"
                label="Giro comercial"
                variant="standard"
                fullWidth
                value={giro}
                onChange={(e) => setGiro(e.target.value)}
              />
            </Grid>
          </Grid>
        </PBBox>
      </Card>

      <PBBox mb={1} mt={2}>
        <Grid container spacing={2} mt={2} mb={7} style={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PBButton
              disabled={accion === "ingresar"}
              variant="gradient"
              color="primary"
              style={{ width: 200 }}
              onClick={() => accionServicios(true)}
            >
              Agregar servicio
            </PBButton>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </Grid>
        </Grid>
      </PBBox>

      <Modal
        id="notificacion"
        open={agregar}
        onClose={() => setAgregar(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            width: "50%",
          }}
        >
          <PBBox display="flex" justifyContent="center">
            <PBTypography variant="a" color="dark" style={{ fontSize: 20 }} textAling="center">
              {titulo}
            </PBTypography>
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBInput
              type="text"
              label="Nombre"
              variant="standard"
              fullWidth
              value={servicio}
              onChange={(e) => setServicio(e.target.value)}
            />
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBInput
              type="text"
              label="Descripción"
              variant="standard"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBInput
              type="text"
              label="Costo"
              variant="standard"
              fullWidth
              value={costo}
              onChange={(e) => setCosto(e.target.value)}
            />
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBInput
              type="text"
              label="Categoria"
              variant="standard"
              fullWidth
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            />
          </PBBox>
          <PBBox>
            <PBButton
              variant="gradient"
              color="primary"
              onClick={titulo === "Ingresar Servicio" ? agregarServicio : actualizarServicio}
              fullWidth
              style={{ marginTop: 20 }}
            >
              {titulo === "Ingresar Servicio" ? "Agregar" : "Actualizar"}
            </PBButton>
          </PBBox>
        </Card>
      </Modal>

      <Modal
        id="notificacion"
        open={confirmar}
        onClose={() => setConfirmar(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            width: "50%",
          }}
        >
          <PBBox display="flex" justifyContent="center">
            <PBTypography variant="a" color="dark" style={{ fontSize: 20 }} textAling="center">
              Estas a punto de borrar el siguiente servicio:
            </PBTypography>
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
              {borrar.nombre}
            </PBTypography>
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
              {borrar.descripcion}
            </PBTypography>
          </PBBox>
          <PBBox display="flex" justifyContent="center">
            <PBButton
              variant="gradient"
              color="primary"
              onClick={borrarServicio}
              style={{ marginTop: 20, marginRight: 5 }}
            >
              Aceptar
            </PBButton>
            <PBButton
              variant="gradient"
              color="primary"
              onClick={() => setConfirmar(false)}
              style={{ marginTop: 20, marginLeft: 5 }}
            >
              Cancelar
            </PBButton>
          </PBBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
};

export default datosEmpresa;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";

// Powerups Components React context
import { useMaterialUIController } from "context";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import Functions from "widgets/Function";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBButton from "components/PBButton";
import PBInput from "components/PBInput";
import Fetchs from "widgets/Fetchs";
import PBFuctions from "components/PBWidgets/PBFunctions";
import manejoErrores from "generales/errores";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

const StyledCard = styled.div`
  padding: 0px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  display: inline-block;
  position: relative;
  box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
`;

function calDividendos(porcentaje, meses, monto, tipo) {
  if (tipo === "1") {
    const porTotal = (porcentaje * meses) / 100;
    return porTotal * monto;
  }
  if (tipo === "2") {
    const porDecimal = porcentaje / 100;
    const porTotal = (1 + porDecimal) ** meses;
    const VF = monto * porTotal;
    const st = VF - monto;
    return st;
  }
  return 0;
}

function calPorDividendos(porcentaje, meses, tipo) {
  if (tipo === "1") return porcentaje * meses;
  if (tipo === "2") {
    const porDecimal = porcentaje / 100;
    const porTotal = (1 + porDecimal) ** meses;
    const porReal = porTotal * 100 - 100;
    return porReal;
  }
  return 0;
}

export default function Contracts() {
  const { RTxt, Round, NumSepCom } = Functions;
  const [controller] = useMaterialUIController();
  const [tabla, setTabla] = useState([]);
  const { nft, account } = controller;
  const { BCPF, IPFnft, BPFnft } = Fetchs;
  const [productos, setProductos] = useState([]);
  const [notificacion, setNotificacion] = useState(false);
  const [editMount, setEditmount] = useState(false);
  const [mount, setMount] = useState(false);
  const [config, setConfig] = useState({
    id: "",
    name: "",
    identifier: "",
    minmount: 0,
    maxmount: 0,
    interest: 0,
    period: {
      unit: "",
      amount: 0,
    },
    typeInterest: "",
    commissions: { percentages: 0 },
  });

  useEffect(() => {
    const resultado = BCPF(nft.contract);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) setProductos(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  }, []);

  const crearProducto = (props) => {
    const financialProduct = props.identifier;
    const startDate = moment().add(1, "M");
    const plazo = props.period.amount + 2;
    const endDate = moment().add(plazo, "M");
    const resultado = IPFnft(
      nft.contract,
      nft.id,
      financialProduct,
      mount,
      startDate,
      endDate,
      account
    );
    resultado
      .then((value) => {
        if (value.statusCode === undefined) alert("Contrato realizado con exíto");
        else alert(manejoErrores(value));
        setNotificacion(false);
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const comprobar = async (term) => {
    const { related } = term;
    let index = false;
    if (
      (mount >= term.minmount && mount <= term.maxmount) ||
      (mount >= term.minmount && term.maxmount === -1)
    ) {
      if (related.length === 0) index = true;
      if (!index) {
        const resultado = await BPFnft(nft.contract, nft.id);
        if (resultado.statusCode === undefined) setTabla(resultado);
        else alert(resultado.message);
        for (let i = 0; i < related.length; i += 1) {
          const y = tabla.findIndex((element) => element.financialProduct === related[i]);
          if (y >= 0) {
            index = true;
          }
        }
      }
      if (index) {
        crearProducto(term);
      } else alert("Aun no tienes acceso a este producto");
    } else alert("El monto esta fuera de los limites");
  };

  const card = (props) => (
    <StyledCard variant="button">
      <PBBox
        py={1}
        px={1}
        variant="gradient"
        style={{
          borderRadius: "10px",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <PBTypography
          color="grey"
          style={{
            fontSize: 17,
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {props.name}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 12,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: 10,
          }}
        >
          {props.identifier}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Dividendo total:</b>{" "}
          {Round(calPorDividendos(props.interest, props.amount, props.typeInterest))}%
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Mínimo:</b> ${PBFuctions.NumSepCom(props.minmount)}&nbsp;USDT
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Máximo:</b>{" "}
          {props.maxmount >= 0 ? `$${PBFuctions.NumSepCom(props.maxmount)} USDT` : "Sin límites"}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Plazo fijo:</b> {props.amount}&nbsp;{props.unit}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Manejo de cuenta:</b> {`${props.commissions.percentages}%`}
        </PBTypography>
        <PBButton
          variant="gradient"
          color="primary"
          fullWidth
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            if (props.minmount < props.maxmount) {
              setEditmount(true);
              setMount(props.maxmount);
            } else if (props.maxmount === -1) {
              setEditmount(true);
              setMount(props.minmount);
            } else {
              setEditmount(false);
              setMount(props.maxmount);
            }
            setConfig(props.allRow);
            setNotificacion(true);
          }}
        >
          Asociarse
        </PBButton>
      </PBBox>
    </StyledCard>
  );
  // prettier-ignore
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox display="flex" flexDirection="row" sx={{ marginTop: 5 }}>
        <Avatar alt={nft.name} src={nft.url} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.id, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      {productos.length === 0
        ? null
        : productos.map((products) => (
          <PBBox>
            <PBTypography
              display="flex"
              mt={6}
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                marginLeft: 10,
                textTransform: "uppercase",
              }}
              color="dark"
            >
              {products.type}
            </PBTypography>
            <Grid container spacing={2} mt={0} mb={2} style={{ width: "100%" }}>
              {products.values.map((row) => (
                <Grid item xs={12} md={4} lg={3} key={row["_id"]}>
                  <PBBox>
                    <Card variant="outlined">
                      {card({
                        id: row["_id"],
                        name: row.name,
                        identifier: row.identifier,
                        minmount: row.minmount,
                        maxmount: row.maxmount,
                        interest: row.interest,
                        unit: row.period.unit,
                        amount: row.period.amount,
                        typeInterest: row.typeInterest,
                        commissions: row.commissions,
                        allRow: row,
                      })}
                    </Card>
                  </PBBox>
                </Grid>
              ))}
            </Grid>
          </PBBox>
        ))}
      <Modal
        id="Comprobacion.inversion"
        open={notificacion}
        onClose={() => setNotificacion(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
          <PBBox display="flex" flexDirection="column" justifyContent="center">
            <PBTypography
              variant="a"
              color="dark"
              style={{
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                textTransform: "uppercase",
              }}
              textAling="center"
            >
              {config.name}
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 12,
                textAlign: "center",
                textTransform: "uppercase",
                marginBottom: 10,
              }}
            >
              {config.identifier}
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 15,
                textAlign: "left",
              }}
            >
              <b>Dividendo total:</b> $
              {NumSepCom(
                Round(
                  calDividendos(config.interest, config.period.amount, mount, config.typeInterest)
                )
              )}{" "}
              USDT
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 15,
                textAlign: "left",
              }}
            >
              <b>Mínimo:</b> ${PBFuctions.NumSepCom(config.minmount)}&nbsp;USDT
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 15,
                textAlign: "left",
              }}
            >
              <b>Máximo:</b>{" "}
              {config.maxmount >= 0
                ? `$${PBFuctions.NumSepCom(config.maxmount)} USDT`
                : "Sin límites"}
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 15,
                textAlign: "left",
              }}
            >
              <b>Plazo fijo:</b> {config.period.amount}&nbsp;{config.period.unit}
            </PBTypography>
            <PBTypography
              color="grey"
              style={{
                fontSize: 15,
                textAlign: "left",
              }}
            >
              <b>Manejo de cuenta:</b> {`${config.commissions.percentages}%`}
            </PBTypography>
          </PBBox>
          {editMount ? (
            <PBBox mt={3} mb={1}>
              <PBInput
                type="number"
                label="Monto"
                variant="standard"
                value={mount}
                fullWidth
                onChange={(e) => setMount(e.target.value)}
              />
            </PBBox>
          ) : null}
          <PBBox mb={1} display="flex" justifyContent="center">
            <PBTypography
              variant="a"
              color="text"
              style={{
                fontSize: 18,
              }}
              textAling="center"
            >
              ¿Seguro que quieres asociarse bajo estas condiciones?
            </PBTypography>
          </PBBox>
          <PBBox>
            <PBButton
              variant="gradient"
              color="primary"
              onClick={() => comprobar(config)}
              fullWidth
            >
              Aceptar
            </PBButton>
          </PBBox>
          <PBBox style={{ marginTop: 5 }}>
            <PBButton
              variant="gradient"
              color="primary"
              onClick={() => setNotificacion(false)}
              fullWidth
            >
              Cancelar
            </PBButton>
          </PBBox>
        </Card>
      </Modal>
    </DashboardLayout>
  );
}

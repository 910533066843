import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBButton from "components/PBButton";

// Powerups React widgets components
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";

// Powerups Components React context
import { useMaterialUIController } from "context";

// Powerups React widgets components
import Comisiones from "../commissions";
import Cuentahabientes from "../accountholders";

const columns = [
  { Header: "Id", accessor: "id", width: "25%", align: "left" },
  { Header: "Sucursal", accessor: "sucursal", width: "20%", align: "left" },
  { Header: "Operaciones", accessor: "oper", width: "10%", align: "left" },
];

function Tables() {
  const [rows, setRows] = useState([]);
  const [nft, setNft] = useState({});
  const [controller] = useMaterialUIController();
  const [notificacion, setNotificacion] = useState(false);
  const [not, setNot] = useState(false);
  const { sidenavColor } = controller;
  const { BNftCon } = Fetchs;

  const comisiones = (e) => {
    setNft(e);
    setNotificacion(true);
  };

  const cuentahabientes = (e) => {
    setNft(e);
    setNot(true);
  };

  const Tabla = (rowsTemp) => {
    const array = [];
    rowsTemp.forEach((e) => {
      array.push({
        id: e["_id"],
        sucursal: e["nftId"],
        oper: (
          <PBBox ml={-1}>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={() => comisiones(e)}
            >
              Comisiones
            </PBButton>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
                marginLeft: 5,
              }}
              onClick={() => cuentahabientes(e)}
            >
              Cuentahabientes
            </PBButton>
          </PBBox>
        ),
      });
    });
    setRows(array);
  };

  const sucursales = async () => {
    let items = [];
    const contract = "0x4c2113ecb4798cb622ec23851f92a606a25e44ea";
    const resultado = BNftCon(contract);
    await resultado
      .then((value) => {
        if (value.statusCode === undefined) items = value;
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
    Tabla(items);
  };

  useEffect(() => {
    sucursales();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <PBBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <PBTypography variant="h6" color="white">
                  Sucursales
                </PBTypography>
              </PBBox>
              <PBBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </PBBox>
            </Card>
          </Grid>
        </Grid>

        <Modal
          id="Comprobacion.inversion"
          open={notificacion}
          onClose={() => setNotificacion(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: "60%",
              maxWidth: "80%",
              maxHeight: "85%",
              overflow: "scroll",
            }}
          >
            <Comisiones nft={nft} />
          </Card>
        </Modal>

        <Modal
          id="Comprobacion.inversion"
          open={not}
          onClose={() => setNot(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: "60%",
              maxWidth: "80%",
              maxHeight: "85%",
              overflow: "scroll",
            }}
          >
            <Cuentahabientes nft={nft} />
          </Card>
        </Modal>
      </PBBox>
    </DashboardLayout>
  );
}

export default Tables;

// Powerups Components React layouts
import Aprendizaje from "layouts/school/index";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  { type: "divider", title: "Herramientas", key: "divider-tools-pages" },
  { type: "title", title: "Herramientas", key: "tools-pages" },
  {
    type: "collapse",
    name: "Aprendizaje",
    key: "aprendizaje",
    icon: <Icon fontSize="small">school_icon</Icon>,
    route: "/aprendizaje",
    component: <Aprendizaje />,
  },
];

export default routes;

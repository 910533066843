// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Powerups React components
import PBBox from "components/PBBox";

// Pages components
import PageLayout from "widgets/LayoutContainers/PageLayout";

function BasicLayout({ image, children, lgLayout, xlLayout }) {
  return (
    <PageLayout>
      <PBBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      />
      <PBBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={0} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={lgLayout} xl={xlLayout}>
            {children}
          </Grid>
        </Grid>
      </PBBox>
    </PageLayout>
  );
}
// Typechecking props for the BasicLayout
BasicLayout.defaultProps = {
  lgLayout: 4,
  xlLayout: 3,
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lgLayout: PropTypes.number,
  xlLayout: PropTypes.number,
};

export default BasicLayout;

import moment from "moment";

/** *************************************************
  Configuraciones generales
************************************************* */
async function LeerConfiguraciones() {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", process.env.REACT_APP_AUTH);
  myHeaders.append("Content-Type", "application/json");
  try {
    const consulta = await fetch(process.env.REACT_APP_API_URL, {
      method: "GET",
    });
    const resultado = await consulta.json();
    const { STATUS, URLAPI } = resultado;
    return { STATUS, URLAPI, myHeaders };
  } catch (error) {
    return error;
  }
  // return { STATUS: true, URLAPI: "http://localhost:4134/api/v1/", myHeaders };
}

/** ***********************************************
  Usuarios
************************************************* */

// Insertar Usuario
async function InsertarUsuario(
  nickname,
  name,
  lastname,
  email,
  lada,
  phone,
  wallet,
  country,
  rol,
  permissions,
  dateAdmission,
  financialProducts,
  membership,
  status,
  password
) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/register`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        nickname,
        name,
        lastname,
        email,
        lada,
        phone,
        wallets: [],
        wallet,
        address: [
          {
            country,
            estate: "",
            city: "",
            address: "",
          },
        ],
        rol,
        permissions,
        dateAdmission,
        financialProducts,
        membership,
        status,
        password,
        isverified: false,
        tax: "",
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Login Usuario
async function LoginUsuario(user, password) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/login`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        user,
        password,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Datos de Usuario
async function DatosUsuario(term) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/${term}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Actualizar Usuario
async function ActualizarUsuario(id, name, lastname, email, lada, phone, country) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/${id}`, {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        name,
        lastname,
        email,
        lada,
        phone,
        address: {
          country,
          estate: "",
        },
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Recuperar contraseña Usuario
async function RecuperarContraseñaUsuario(id) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/password/${id}`, {
      method: "PATCH",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Cambiar contraseña Usuario
async function CambiarContraseniaUsuario(id, password, newPassword) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/newpassword/${id}`, {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        password,
        newPassword,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar usuarios
async function BuscarUsuarios(parametros) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users?${parametros}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar usuario
async function BuscarUsuario(id) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}users/${id}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Comisiones
************************************************* */

// Buscar comisiones por nft
async function BuscarComisionesnft(contract, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}commissions?contract=${contract}&nftNumber=${nftId}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Productos financieros
************************************************* */

// Buscar productos financieros por nft
async function BuscarProductosFinancierosnft(contract, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(
      `${URLAPI}financialproducts?contract=${contract}&nftNumber=${nftId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar productos financieros por estado
async function BuscarProductosFinancierosestado(contract, nft) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    let txt = "?limit=1000";
    if (contract !== undefined && contract !== "") txt += `&contract=${contract}`;
    if (nft !== undefined && nft !== "") txt += `&nftNumber=${nft}`;
    const consulta = await fetch(`${URLAPI}financialproducts${txt}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Insertar productos financieros por nft
async function InsertarProductosFinancierosnft(
  contract,
  nftId,
  financialProduct,
  amount,
  startDate,
  endDate,
  user
) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}financialproducts`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        contract,
        nftId,
        financialProduct,
        amount,
        totalInterest: 0,
        totalWithdrawal: 0,
        withdrawal: [],
        creationDate: new Date(),
        approvalDate: new Date(),
        startDate,
        endDate,
        percentageCompletion: 0,
        status: "0",
        user,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Insertar productos gold por nft
async function InsertarProductosGold(
  contract,
  nftId,
  financialProduct,
  amount,
  startDate,
  endDate,
  user
) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}financialproducts/gold`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        contract,
        nftId,
        financialProduct,
        amount,
        totalInterest: 0,
        totalWithdrawal: 0,
        withdrawal: [],
        creationDate: new Date(),
        approvalDate: new Date(),
        startDate,
        endDate,
        percentageCompletion: 0,
        status: "100",
        user,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Movimientos
************************************************* */
// Buscar movimiento por usuario
async function BuscarMovimientoUsuario(user, status) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}movements?idUser=${user}&status=${status}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar movimiento por nft
async function BuscarMovimientoNft(contract, nftId, status) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(
      `${URLAPI}movements?nftId=${nftId}&contract=${contract}&status=${status}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Nft
************************************************* */
// Buscar nfts
async function BuscarNft(contract, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts?nftId=${nftId}&contract=${contract}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar nfts por contrato
async function BuscarNftContrato(contract) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts?contract=${contract}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar nfts por id de usuario
async function BuscarNftUser(user) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts?user=${user}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Buscar cuentahabientes por nft
async function BuscarCuentahabientesNft(contract, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(
      `${URLAPI}nfts?relatedIdtoken=${nftId}&relatedContract=${contract}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Reclamar nft
async function ReclamarNft(wallet, user, contract, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts/claim`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        wallet,
        user,
        contract,
        nftId,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Agregar nft
async function AgregarNft(idUser, nftId) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        user: idUser,
        usersHistory: [{ id: idUser, registrationDate: new Date() }],
        contract: "GOLD",
        nftId,
        image: "https://edbit.online/imagenes/ImagenGold.jpg",
        blockchain: "n/a",
        name: `GOLD # ${nftId}`,
        description: `Coleccion de NFT's que te da acceso a todos los beneficios de socio GOLD`,
        wallet: 0,
        relatedContract: "",
        relatedIdtoken: "",
        lastoperation: new Date(),
        status: true,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

// Transferir usdt a usuario
async function TranferirNft(contract, nftId, date, user, amount) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}nfts/transfers`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        contract,
        nftId,
        date,
        user,
        amount,
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  System
************************************************* */
// Buscar system por nombre
async function BuscarSystemName(name) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}system/${name}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Coinpayments
************************************************* */
// Abre un ticket en coinpayment
async function AbrirTicketCoinpayments(idUser, amountUsdt, contract = "", nftId = "") {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}movements/coinpayments`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        creationDate: moment.utc(),
        approvalDate: moment.utc(),
        comments: "Recarga de billetera",
        concept: "10001",
        product: "",
        productType: "30002",
        notes: "",
        contract,
        nftId,
        idUser,
        beneficiary: "",
        paymentMethod: "20006",
        reference: "",
        address: "",
        url: "",
        qr: "",
        originalAmount: {
          amount: amountUsdt,
          currency: "USDT",
          date: moment.utc(),
          amountTC: 1,
          dateTC: moment.utc(),
        },
        amountUsdt,
        status: "",
      }),
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Configuraciones de productos financieros
************************************************* */
// Buscar configuraciones productos financieros por nft
async function BuscarConfiguracionesProductosFinancieros(contract) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(
      `${URLAPI}settingsfinancialproducts/contracts?contract=${contract}&status=1`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Crypto
************************************************* */
// Buscar system por nombre
async function BuscarPriceCrypto(crypto) {
  try {
    const configuraciones = await LeerConfiguraciones();
    const { STATUS, URLAPI, myHeaders } = configuraciones;
    if (!STATUS)
      return {
        statusCode: 401,
        message:
          "El sistema se encuentra en mantenimiento programado y está fuera de línea temporalmente.",
        error: "Unauthorized",
      };
    const consulta = await fetch(`${URLAPI}cryptoprices/last/${crypto}`, {
      method: "GET",
      headers: myHeaders,
    });
    const resultado = await consulta.json();
    return resultado;
  } catch (error) {
    return error;
  }
}

/** ***********************************************
  Expresiones de lectura
************************************************* */

export default {
  /** ***********************************************
    Usuarios
  ************************************************* */
  InserUs: InsertarUsuario, // Insertar Usuario
  LogUs: LoginUsuario, // Login Usuario
  DatUs: DatosUsuario, // Datos de Usuario
  ActUs: ActualizarUsuario, // Actualizar Usuario
  RCUs: RecuperarContraseñaUsuario, // Recuperar contraseña usuario
  CCUs: CambiarContraseniaUsuario, // Cambiar contraseña Usuario
  BusUs: BuscarUsuarios, // Buscar Usuarios
  BusU: BuscarUsuario, // Buscar usuario
  /** ***********************************************
    Productos financieros
  ************************************************* */
  IPFnft: InsertarProductosFinancierosnft, // Insertar productos financieros por nft
  IPGold: InsertarProductosGold, // Insertar productos gold por nft
  BPFnft: BuscarProductosFinancierosnft, // Buscar productos financieros por nft
  BPFsts: BuscarProductosFinancierosestado, // Buscar productos financieros por estado
  BCPF: BuscarConfiguracionesProductosFinancieros,
  /** ***********************************************
    Movimientos
  ************************************************* */
  BMUse: BuscarMovimientoUsuario, // Buscar movimiento por usuario
  BMNft: BuscarMovimientoNft, // Buscar movimiento por nft
  /** ***********************************************
    Coinpayments
  ************************************************* */
  // Buscar movimiento por usuario
  ATCoin: AbrirTicketCoinpayments,
  /** ***********************************************
    System
  ************************************************* */
  BSName: BuscarSystemName, // Buscar system por nombre
  /** ***********************************************
    Comisiones
  ************************************************* */
  BCnft: BuscarComisionesnft, // Buscar comisiones por nft
  /** ***********************************************
    Nft
  ************************************************* */
  BNft: BuscarNft, // Buscar nfts
  ANft: AgregarNft, // Agregar nft
  BCNFT: BuscarCuentahabientesNft, // Buscar cuentahabientes por nft
  TNFT: TranferirNft, // Transferir usdt a usuario
  RNft: ReclamarNft, // Reclamar propiedad del Nft
  BNftUs: BuscarNftUser, // Buscar nfts por id de usuario
  BNftCon: BuscarNftContrato, // Buscar nfts por contrato
  /** ***********************************************
    Crypto
  ************************************************* */
  BPCry: BuscarPriceCrypto, // Buscar system por nombre
};

// React
import React from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import Fetchs from "widgets/Fetchs";
import FunGenerales from "widgets/Function";
import Codigos from "generales/codigos";
import manejoErrores from "generales/errores";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images, logos, icons
import bgImage from "assets/fondos/portada.jpg";
import Logo from "assets/logos/edbit.svg";
import { FcInfo } from "react-icons/fc";

// Powerups Components React contexts
import { useMaterialUIController, setAccount, setLogin } from "context";
import jwtDecode from "jwt-decode";

function Cover() {
  const [usuario, setUsuario] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [lada, setLada] = React.useState({ pais: "México", codigo: "+52" });
  const [telefono, setTelefono] = React.useState(0);
  const [contraseña, setContraseña] = React.useState("");
  const [cContraseña, setCContraseña] = React.useState("");
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { InserUs } = Fetchs;
  const { SoloMinNum } = FunGenerales;

  const InsertarUsuario = () => {
    const resultado = InserUs(
      usuario,
      nombre,
      apellido,
      correo,
      lada.codigo,
      telefono,
      0,
      lada.pais,
      "cliente",
      [],
      new Date(),
      [],
      [],
      "100",
      contraseña
    );
    resultado
      .then((value) => {
        if (value.token !== undefined && value.statusCode === undefined) {
          const decoded = jwtDecode(value.token);
          setLogin(dispatch, true);
          setAccount(dispatch, decoded.id);
          navigate("/escritorio");
        } else {
          alert(manejoErrores(value));
        }
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const Verificar = () => {
    if (cContraseña !== contraseña) alert("Las contraseñas no son iguales");
    else InsertarUsuario();
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ height: 600 }}>
        <PBBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <PBBox component="img" src={Logo} alt="Brand" width="5rem" />
          <PBTypography variant="h4" fontWeight="medium" color="white" mt={0}>
            ¡Bienvenido a la familia!
          </PBTypography>
          <PBTypography variant="button" color="text">
            ¿Tienes una cuenta?{" "}
            <PBTypography
              component={Link}
              to="/"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Entrar
            </PBTypography>
          </PBTypography>
        </PBBox>
        <PBBox pt={2} pb={3} px={3} id="arriba" sx={{ overflow: "scroll" }}>
          <PBBox component="form" role="form">
            <PBBox mb={1}>
              <PBInput
                type="text"
                label="Nickname"
                variant="standard"
                fullWidth
                value={usuario}
                onChange={(e) => setUsuario(SoloMinNum(e.target.value, 15))}
              >
                <Tooltip
                  style={{ fontSize: 5 }}
                  title="Solo se permiten letras y números, con un máximo de 20 caracteres."
                >
                  <IconButton>
                    <FcInfo size={15} m={0} p={0} />
                  </IconButton>
                </Tooltip>
              </PBInput>
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="text"
                label="Nombre"
                variant="standard"
                fullWidth
                onChange={(e) => setNombre(e.target.value)}
              />
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="text"
                label="Apellido"
                variant="standard"
                fullWidth
                onChange={(e) => setApellido(e.target.value)}
              />
            </PBBox>

            <PBBox mb={1}>
              <Autocomplete
                id="combo-box-demo"
                value={lada}
                onChange={(event, newValue) => {
                  setLada(newValue);
                }}
                options={Codigos}
                getOptionLabel={(option) => `${option.pais} (${option.codigo})`}
                renderInput={(params) => <TextField {...params} label="Pais" variant="standard" />}
              />
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="number"
                label="Teléfono"
                variant="standard"
                fullWidth
                onChange={(e) => setTelefono(e.target.value)}
              />
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setCorreo(e.target.value)}
              />
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="password"
                label="Contraseña"
                variant="standard"
                fullWidth
                onChange={(e) => setContraseña(e.target.value)}
              />
            </PBBox>

            <PBBox mb={1}>
              <PBInput
                type="password"
                label="Confirmar contraseña"
                variant="standard"
                fullWidth
                onChange={(e) => setCContraseña(e.target.value)}
              />
            </PBBox>

            <PBBox display="flex" alignItems="center" ml={-1}>
              <PBTypography
                component="a"
                href="https://edbit.finance/terminos-condiciones/"
                target="_blank"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Al dar click en registrar aceptas los terminos y condiciones que aqui se exponen
              </PBTypography>
            </PBBox>

            <PBBox mt={4} mb={1}>
              <PBButton variant="gradient" color="primary" fullWidth onClick={() => Verificar()}>
                Registrar
              </PBButton>
            </PBBox>
          </PBBox>
        </PBBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;

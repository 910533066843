import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Powerups Components React Context Provider
import { MaterialUIControllerProvider } from "context";

// Library for metamask
import { MetaMaskProvider } from "metamask-react";

ReactDOM.render(
  <MetaMaskProvider>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </MetaMaskProvider>,
  document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Chart } from "react-google-charts";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import Fuction from "widgets/Function";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Divider from "@mui/material/Divider";
import CalcularInteres from "../../generales/calcularInteres";

const StyledCard = styled.div`
  padding: 0px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  display: inline-block;
  position: relative;
  box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
`;

export default function Contracts(term) {
  const { producto, nft } = term;
  const startDate = moment.utc(producto.startDate).format("YYYY-MM-DD");
  const end = moment.utc();
  const [maximo, setMaximo] = useState(0);
  const [actual, setActual] = useState(0);
  const [comision, setComicion] = useState(0);
  const [total, setTotal] = useState(0);
  const { Round, RTxt, NumSepCom } = Fuction;
  const [avance, setAvance] = useState(0);

  useEffect(async () => {
    const { unit } = producto.Settings[0].period;
    const CI = producto.amount;
    const i = producto.Settings[0].interest / 100;
    const n = producto.Settings[0].period.amount;
    const pc = producto.Settings[0].commissions.percentages / 100;
    const ti = producto.Settings[0].typeInterest;
    const { acumulado, st, t, cm, diff } = await CalcularInteres(
      unit,
      CI,
      i,
      n,
      pc,
      ti,
      startDate,
      end
    );
    setActual(Round(acumulado));
    setMaximo(Round(st));
    setTotal(Round(t));
    setComicion(Round(cm));
    setAvance(Round(diff));
  }, []);

  const card = (props) => (
    <StyledCard variant="button">
      <PBBox
        py={1}
        px={1}
        variant="gradient"
        style={{
          borderRadius: "10px",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            fontWeight: "bold",
            textAlign: "left",
            textTransform: "uppercase",
          }}
        >
          <b>Nombre:</b> {props.settings.name}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Id:</b> {props.id}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Identificador:</b> {props.settings.identifier}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>F. solicitud:</b> {moment.utc(props.creationDate).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>F. aprovación:</b> {moment.utc(props.approvalDate).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>F. inicio:</b> {moment.utc(props.startDate).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>F. final:</b> {moment.utc(props.endDate).format("YYYY-MM-DD")}
        </PBTypography>

        <Divider />

        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Capital inicial:</b> {`$${NumSepCom(producto.amount)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Tasa mensual:</b> {props.settings.interest}%{" "}
          {props.settings.typeInterest === "1" ? "(Simple)" : "(Compuesto)"}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Dividendos totales:</b> {`$${NumSepCom(maximo)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Total de comiciones:</b> {`$${NumSepCom(comision)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Total a cobrar:</b> {`$${NumSepCom(total)} USDT`}
        </PBTypography>
      </PBBox>
    </StyledCard>
  );

  return (
    <PBBox>
      <PBBox display="flex" flexDirection="row">
        <Avatar alt={nft.name} src={nft.url} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.id, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      <Grid container spacing={3} sx={{ marginTop: "0.2px" }}>
        <Grid item xs={12} sm={6}>
          <Chart
            chartType="PieChart"
            width="100%"
            data={[
              ["Rendimiento", "Monto"],
              [
                `Plazo total: ${producto.Settings[0].period.amount} ${producto.Settings[0].period.unit}`,
                producto.Settings[0].period.amount,
              ],
              [
                `Avance: ${avance} ${producto.Settings[0].period.unit} - $${NumSepCom(
                  actual
                )} USDT`,
                avance,
              ],
            ]}
            options={{
              title: "Avance en meses",
              pieHole: 0.5,
              slices: {
                0: { color: "#1A1833" },
                1: { color: "#684191" },
              },
              chartArea: {
                left: 10,
                top: 40,
                width: "100%",
                height: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            {card({
              id: producto["_id"],
              name: producto.name,
              amount: producto.amount,
              creationDate: producto.creationDate,
              approvalDate: producto.approvalDate,
              startDate: producto.startDate,
              endDate: producto.endDate,
              settings: producto.Settings[0],
            })}
          </Card>
        </Grid>
      </Grid>
    </PBBox>
  );
}

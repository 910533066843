import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import PBTypography from "components/PBTypography";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";

// Images y logos
import bgImage from "assets/fondos/portada.jpg";
import Logo from "assets/logos/edbit.svg";

// PowerLife Dashboard React contexts
import { useMaterialUIController, setAccount, setLogin, setToken } from "context";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

function Basic() {
  const { LogUs, RCUs } = Fetchs;
  const [, dispatch] = useMaterialUIController();
  const [usuario, setUsuario] = React.useState("");
  const [contraseña, setContraseña] = React.useState("");
  const [recontrar, setRecontrar] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const sUsrAg = navigator.userAgent;
    if (
      sUsrAg.indexOf("Chrome") < 0 &&
      sUsrAg.indexOf("Firefox") < 0 &&
      sUsrAg.indexOf("Safari") > -1
    ) {
      window.alert(
        `Detectamos que estás utilizando 'Safari' como navegador, asegúrate que tengas desactivado el bloqueo de pestañas emergentes, o utiliza el navegador Chrome, para un mejor funcionamiento.`
      );
    }
    const URLactual = window.location;
    if (URLactual === "http://localhost:3000/" || URLactual === "https://edbit.online/") {
      setAccount(dispatch, {});
      setLogin(dispatch, false);
    } else {
      navigate("/");
    }
  }, []);

  const RecuperarContrasenia = () => {
    const resultado = RCUs(usuario);
    resultado
      .then((value) => {
        if (value) {
          setRecontrar(false);
          alert("Su nueva contraseña fue enviada a su correo");
        } else {
          alert("Corre incorrecto");
        }
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const LoginUsuario = () => {
    const resultado = LogUs(usuario, contraseña);
    resultado
      .then((value) => {
        if (value.token !== undefined && value.statusCode === undefined) {
          const decoded = jwtDecode(value.token);
          console.log(value.token);
          setToken(dispatch, value.token);
          setLogin(dispatch, true);
          setAccount(dispatch, decoded.id);
          navigate("/escritorio");
        } else {
          alert(manejoErrores(value));
        }
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <PBBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <PBBox component="img" src={Logo} alt="Brand" width="4rem" />
          <PBTypography variant="h5" fontWeight="medium" color="white" mt={0}>
            ¡Nos alegramos de verte!
          </PBTypography>
          <PBTypography variant="button" color="text">
            ¿No tienes una cuenta?{" "}
            <PBTypography
              component={Link}
              to="/registro"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Regístrate
            </PBTypography>
          </PBTypography>
        </PBBox>
        <PBBox pt={4} pb={3} px={3}>
          <PBBox component="form" role="form">
            <PBBox mb={1}>
              <PBInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setUsuario(e.target.value)}
              />
            </PBBox>
            <PBBox mb={1}>
              <PBInput
                type="password"
                label="Contraseña"
                variant="standard"
                fullWidth
                onChange={(e) => setContraseña(e.target.value)}
              />
            </PBBox>
            <PBBox mt={1} mb={1}>
              <PBButton variant="text" color="primary" onClick={() => setRecontrar(true)} fullWidth>
                ¿Olvidaste tu contraseña?
              </PBButton>
            </PBBox>
            <PBBox mt={1} mb={1}>
              <PBButton variant="gradient" color="primary" onClick={LoginUsuario} fullWidth>
                Entrar
              </PBButton>
            </PBBox>
          </PBBox>
        </PBBox>
      </Card>

      <Modal
        open={recontrar}
        onClose={() => setRecontrar(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
          <PBBox mb={1}>
            <PBTypography variant="h5" fontWeight="medium" mt={0}>
              Introduce tu correo electrónico
            </PBTypography>
          </PBBox>
          <PBBox mb={1}>
            <PBInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              onChange={(e) => setUsuario(e.target.value)}
            />
          </PBBox>
          <PBBox mt={1} mb={1}>
            <PBButton
              variant="gradient"
              color="primary"
              onClick={() => RecuperarContrasenia()}
              fullWidth
            >
              Enviar
            </PBButton>
          </PBBox>
        </Card>
      </Modal>
    </BasicLayout>
  );
}

export default Basic;

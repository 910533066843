import React from "react";
import PBTypography from "components/PBTypography";
import IconButton from "@mui/material/IconButton";
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import Card from "@mui/material/Card";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ticketCoinpayments = (props) => {
  const { ticket, salir } = props;
  const { qr, address, amountUsdt, reference, url } = ticket;

  return (
    <Card style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}>
      <PBBox display="flex" justifyContent="center">
        <PBBox component="img" src={qr} alt="Brand" width="15rem" />
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          Dirección de pago:
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography
          variant="a"
          color="dark"
          style={{ fontSize: 15, fontWeight: "bold" }}
          textAling="center"
        >
          {address}
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <IconButton aria-label="" onClick={() => navigator.clipboard.writeText(address)}>
          <ContentCopyIcon />
        </IconButton>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography
          variant="a"
          color="dark"
          style={{ fontSize: 15, fontWeight: "bold" }}
          textAling="center"
        >
          {`Total a transferir: $${parseFloat(amountUsdt) + 1} USDT.TRC20`}
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          Desglose:
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          {`Compra: $${parseFloat(amountUsdt)} USDT.TRC20`}
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          Para gas fee: $1 USDT.TRC20
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          Folio:
        </PBTypography>
        <PBTypography
          variant="a"
          color="dark"
          style={{ fontSize: 15, marginLeft: 10 }}
          textAling="center"
        >
          {reference}
        </PBTypography>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography
          variant="a"
          color="dark"
          style={{ fontSize: 15, marginLeft: 10 }}
          textAling="center"
        >
          Procesador de pago Coinpayments
        </PBTypography>
      </PBBox>
      <PBBox>
        <PBButton variant="text" color="primary" href={url} target="_blank" fullWidth>
          Ver ticket en coinpayments
        </PBButton>
      </PBBox>
      <PBBox>
        <PBButton variant="gradient" color="primary" onClick={() => salir()} fullWidth>
          Aceptar
        </PBButton>
      </PBBox>
      <PBBox mb={1} display="flex" justifyContent="center">
        <PBTypography variant="a" color="dark" style={{ fontSize: 15 }} textAling="center">
          Este ticket es válido solo durante 2, después de ese tiempo tienes que generar uno nuevo.
        </PBTypography>
      </PBBox>
    </Card>
  );
};

export default ticketCoinpayments;

import { useEffect, useState } from "react";

// Powerups Components React context
import { useMaterialUIController, setAccount } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import CalculateIcon from "@mui/icons-material/Calculate";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";

// react-router components
import { useNavigate } from "react-router-dom";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import PBTypography from "components/PBTypography";
import PBInput from "components/PBInput";

// Powerups React widgets components
import manejoErrores from "generales/errores";
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import Fetchs from "widgets/Fetchs";
import Funciones from "widgets/Function";
import Estados from "layouts/authentication/components/state";
import DataMetamask from "../authentication/components/data";

function Dashboard() {
  const { DatUs, BPCry, BSName, BNftUs } = Fetchs;
  const [price, setPrice] = useState(0);
  const [calculadora, setCalculadora] = useState(false);
  const [reclamar, setReclamar] = useState(false);
  const [pesos, setPesos] = useState(0);
  const [dolares, setDolares] = useState(0);
  const { NumSepCom, Round } = Funciones;
  const [controller, dispatch] = useMaterialUIController();
  const { account, login } = controller;
  const [nfts, setNfts] = useState([]);
  const [datos, setDatos] = useState({
    wallet: 0,
  });
  const navigate = useNavigate();

  const configsButton = (
    <PBBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="2.8rem"
      height="2.8rem"
      bgColor="primary"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right=".8rem"
      bottom=".8rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={() => setReclamar(true)}
    >
      <Icon fontSize="small" color="white">
        add_icon
      </Icon>
    </PBBox>
  );

  const recargar = async () => {
    try {
      let contratos = [];
      let items = [];

      const userData = DatUs(account);
      await userData
        .then((value) => {
          setAccount(dispatch, account);
          setDatos(value);
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });

      const contratosAceptados = BSName("contracts");
      await contratosAceptados
        .then((value) => {
          if (value.statusCode === undefined) contratos = value.values;
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });

      const resultado = BNftUs(account);
      await resultado
        .then((value) => {
          if (value.statusCode === undefined) items = value;
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });

      const array = [];

      for (let i = 0; i < items.length; i += 1) {
        const contract = String(items[i].contract);
        const busqueda = contratos.find((e) => e.value === contract);
        if (busqueda !== undefined) {
          const { subvalue } = busqueda;
          const permisos = subvalue.split(",");
          array.push({
            id: items[i].nftId,
            blockchain: items[i].blockchain,
            name: items[i].name,
            description: items[i].description,
            contract,
            url: items[i].image,
            permisos,
          });
        }
      }
      setNfts(array);
    } catch (error) {
      alert(manejoErrores(error));
    }
  };

  useEffect(() => {
    const resultado = BPCry("USDT");
    resultado
      .then((value) => {
        const prices = value[0].price;
        const currency = prices.find((e) => e.currency === "MXN");
        setPrice({
          symbol: currency.symbol,
          value: Round(currency.value * 1.04 * 1.005),
          currency: currency.currency,
        });
      })
      .catch();
    if (login) {
      recargar();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox py={3} style={{ height: "90%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PBBox mb={1.5}>
              <PBTypography
                display="flex"
                style={{ textAlign: "center", fontSize: 13, color: "#A7B0BD", fontWeight: "bold" }}
                color="dark"
              >
                Saldo en billetera
              </PBTypography>
              <PBTypography
                display="flex"
                style={{ textAlign: "center", fontSize: 30, fontWeight: "bold" }}
                color="dark"
              >
                ${NumSepCom(Round(datos.wallet))} USDT
              </PBTypography>
              <PBButton
                variant="gradient"
                color="primary"
                startIcon={<CalculateIcon />}
                sx={{
                  margin: 0,
                  padding: 1,
                }}
                onClick={() => setCalculadora(true)}
              >
                {`$ 1 USDT = ${price.symbol} ${price.value} ${price.currency}`}
              </PBButton>
            </PBBox>
          </Grid>
          <Grid item xs={12}>
            <DataMetamask nfts={nfts} />
          </Grid>
        </Grid>
        {configsButton}
      </PBBox>

      <Modal
        id="qr"
        open={reclamar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card
          style={{
            width: "50%",
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Estados
            idUser={account}
            salir={() => {
              recargar();
              setReclamar(false);
            }}
          />
        </Card>
      </Modal>

      <Modal
        id="qr"
        open={calculadora}
        onClose={() => setCalculadora(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card
          style={{
            width: "50%",
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <PBTypography textAlign="center">
            {`$ 1 USDT = ${price.symbol} ${price.value} ${price.currency}`}
          </PBTypography>
          <PBInput
            type="number"
            label="Pesos"
            variant="standard"
            fullWidth
            value={Round(pesos)}
            onChange={(e) => {
              setDolares(e.target.value / price.value);
              setPesos(e.target.value);
            }}
          />
          <PBInput
            type="number"
            label="USDT"
            variant="standard"
            fullWidth
            value={Round(dolares)}
            onChange={(e) => {
              setPesos(e.target.value * price.value);
              setDolares(e.target.value);
            }}
          />
        </Card>
      </Modal>
    </DashboardLayout>
  );
}

export default Dashboard;

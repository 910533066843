import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { Autocomplete, TextField } from "@mui/material";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBButton from "components/PBButton";
import PBBadge from "components/PBBadge";

// Powerups React widgets components
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import Fuction from "widgets/Function";
import manejoErrores from "generales/errores";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";

// Powerups Components React context
import { useMaterialUIController } from "context";

// Powerups React widgets components
import Details from "../details";

// Assets
import Contratos from "../../../../assets/json/contratos.json";

const columns = [
  { Header: "Id", accessor: "id", align: "left" },
  { Header: "NFT", accessor: "nftId", align: "left" },
  { Header: "Estado", accessor: "status", width: "5%", align: "left" },
  { Header: "Operaciones", accessor: "oper", width: "5%", align: "left" },
];

function Tables() {
  const [rows, setRows] = React.useState([]);
  const [nfts, setNfts] = useState({});
  const [user, setUser] = useState({});
  const [controller] = useMaterialUIController();
  const [notificacion, setNotificacion] = React.useState(false);
  const [producto, setProducto] = React.useState({});
  const { sidenavColor } = controller;
  const { BNftCon, BNft, BusU } = Fetchs;
  const { RTxt } = Fuction;
  const [contract, setContract] = useState(Contratos[0]);

  const leerNFTs = async (elem) => {
    try {
      let items = {};
      let us = {};

      const resultado = BNft(elem.contract, elem.nftId);
      await resultado
        .then((value) => {
          if (value.statusCode === undefined)
            items = value.find((e) => e.nftId === elem.nftId && e.contract === elem.contract);
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
      const resultadoUS = BusU(items.user);
      await resultadoUS
        .then((value) => {
          if (value.statusCode === undefined) us = value;
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
      const array = {
        id: items.nftId,
        blockchain: items.blockchain,
        name: items.name,
        description: items.description,
        contract: items.contract,
        url: items.image,
      };
      setUser(us);
      setNfts(array);
      setNotificacion(true);
    } catch (error) {
      alert(manejoErrores(error));
    }
  };

  const Tabla = (rowsTemp) => {
    const array = [];
    for (let i = 0; i < rowsTemp.length; i += 1) {
      let colores = { color: "warning", texto: "Sin iniciar" };

      if (rowsTemp[i].status === true) {
        colores = { color: "success", texto: "Activo" };
      } else {
        colores = { color: "error", texto: "Finalizado" };
      }

      array.push({
        id: rowsTemp[i]["_id"],
        nftId: RTxt(rowsTemp[i]["nftId"], 4, 4),
        status: (
          <PBBox ml={-1}>
            <PBBadge
              badgeContent={colores.texto}
              color={colores.color}
              variant="gradient"
              size="md"
            />
          </PBBox>
        ),
        oper: (
          <PBBox ml={-1}>
            <PBButton
              variant="gradient"
              color="primary"
              style={{
                fontSize: 10,
                padding: 10,
              }}
              onClick={() => {
                leerNFTs(rowsTemp[i]);
                setProducto(rowsTemp[i]);
              }}
            >
              Ver detalles
            </PBButton>
          </PBBox>
        ),
      });
    }
    setRows(array);
  };

  const Buscar = (c) => {
    const resultado = BNftCon(c);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) Tabla(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    Buscar();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="combo-box-demo"
                  value={contract}
                  onChange={(event, newValue) => {
                    setContract(newValue);
                  }}
                  options={Contratos}
                  getOptionLabel={(option) => `${option.nombre}`}
                  renderInput={(params) => (
                    <TextField {...params} label="Pais" variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <PBButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={() => Buscar(contract["id"])}
                >
                  Buscar Contrato
                </PBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <PBBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <PBTypography variant="h6" color="white">
                  Productos
                </PBTypography>
              </PBBox>
              <PBBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </PBBox>
            </Card>
          </Grid>
        </Grid>

        <Modal
          id="Comprobacion.inversion"
          open={notificacion}
          onClose={() => setNotificacion(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: "60%",
              maxWidth: "80%",
              maxHeight: "85%",
              overflow: "scroll",
            }}
          >
            <Details producto={producto} nft={nfts} user={user} />
          </Card>
        </Modal>
      </PBBox>
    </DashboardLayout>
  );
}

export default Tables;

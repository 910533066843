import React, { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBBadge from "components/PBBadge";
import PBButton from "components/PBButton";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import Cuentas from "generales/cuentas";

// Powerups Components React context
import { useMaterialUIController } from "context";

const columns = [
  { Header: "usuario", accessor: "usuario", width: "15%", align: "left" },
  { Header: "nombre", accessor: "nombre", align: "left" },
  { Header: "lada", accessor: "lada", width: "15%", align: "left" },
  { Header: "telefono", accessor: "telefono", width: "10%", align: "left" },
  { Header: "cuenta", accessor: "cuenta", width: "15%", align: "left" },
  { Header: "nft", accessor: "nft", width: "5%", align: "left" },
  { Header: "estado", accessor: "estado", width: "10%", align: "left" },
];

function Tables() {
  const [rows, setRows] = React.useState([]);
  const [controller] = useMaterialUIController();
  const { sidenavColor, account } = controller;
  const [cuenta, setCuenta] = React.useState(Cuentas[0]);
  const [pagar, setPagar] = React.useState(false);
  const [usuario, setUsuario] = React.useState({});
  const { UsSuc, InsPR } = Fetchs;

  const Activar = (datos) => {
    if (datos.estado === "100") alert("El usuario ya esta activo");
    else {
      setPagar(true);
      setUsuario(datos);
    }
  };

  const Tabla = (rowsTemp) => {
    const array = [];
    for (let i = 0; i < rowsTemp.length; i += 1) {
      let colores = { color: "warning", texto: "Inactivo" };
      let nftTemp = { icon: "cancel", color: "error" };
      if (rowsTemp[i].estado === "100") {
        colores = { color: "success", texto: "Activo" };
      } else if (rowsTemp[i].estado === "0") {
        colores = { color: "error", texto: "Bloqueado" };
      }
      const cuentaTipo = Cuentas.find(
        (dato) => parseInt(dato.tipo, 10) === parseInt(rowsTemp[i].cuenta, 10)
      );
      if (rowsTemp[i].nft === "1") {
        nftTemp = { icon: "check_circle", color: "success" };
      }
      array.push({
        usuario: rowsTemp[i].usuario,
        nombre: rowsTemp[i].nombre,
        lada: rowsTemp[i].lada,
        telefono: rowsTemp[i].telefono,
        cuenta: cuentaTipo.cuenta,
        nft: (
          <PBBox ml={-1}>
            <Icon fontSize="small" color={nftTemp.color}>
              {nftTemp.icon}
            </Icon>
          </PBBox>
        ),
        estado: (
          <PBBox ml={-1}>
            <PBBadge
              badgeContent={colores.texto}
              color={colores.color}
              variant="gradient"
              size="sm"
              onClick={() => Activar(rowsTemp[i])}
            />
          </PBBox>
        ),
      });
    }
    setRows(array);
  };

  const Buscar = () => {
    const resultado = UsSuc(account.idUsuario);
    resultado
      .then((value) => {
        if (value.status) {
          Tabla(value.mensaje);
        } else {
          alert(value.mensaje);
        }
      })
      .catch();
  };

  useEffect(() => {
    Buscar();
  }, []);

  const InsertarPago = () => {
    const resultado = InsPR(
      account.idUsuario,
      usuario.usuario,
      cuenta.valor,
      usuario.id,
      cuenta.tipo
    );
    resultado
      .then((value) => {
        if (value.status) {
          alert("Activado correctamente");
          setPagar(false);
          Buscar();
        } else {
          alert(value.mensaje);
        }
      })
      .catch();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox pt={6} pb={3} mb={7}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <PBBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <PBTypography variant="h6" color="white">
                  Clientes de secursal
                </PBTypography>
              </PBBox>
              <PBBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </PBBox>
            </Card>
          </Grid>
        </Grid>
      </PBBox>

      <Modal
        id="notificacion"
        open={pagar}
        onClose={() => setPagar(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
            width: "50%",
          }}
        >
          <PBBox display="flex" justifyContent="center">
            <PBTypography variant="a" color="dark" style={{ fontSize: 20 }} textAling="center">
              Selecciona el tipo de cuenta
            </PBTypography>
          </PBBox>
          <PBBox mb={1}>
            <Autocomplete
              id="combo-box-demo"
              value={cuenta}
              onChange={(event, newValue) => {
                setCuenta(newValue);
              }}
              options={Cuentas}
              getOptionLabel={(option) => `${option.cuenta} ($${option.valor} USDT)`}
              renderInput={(params) => <TextField {...params} label="Cuenta" variant="standard" />}
            />
          </PBBox>
          <PBButton onClick={InsertarPago}> Pagar </PBButton>
          <PBButton onClick={() => setPagar(false)}> Cancelar </PBButton>
        </Card>
      </Modal>
    </DashboardLayout>
  );
}

export default Tables;

import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import moment from "moment";

// Powerups Components React context
import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// react-router components
import { useNavigate } from "react-router-dom";

// ICONS
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CropFreeIcon from "@mui/icons-material/QrCodeScanner";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";
import PBInput from "components/PBInput";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PBTypography from "components/PBTypography";

// Powerups Components React examples
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DefaultInfoCard from "widgets/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Transactions from "layouts/billing/components/Transactions";
import Funciones from "widgets/Function";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";
import TicketCoinpayments from "./components/ticket";

function Billing() {
  const [controller] = useMaterialUIController();
  const { NumSepCom, Round, RTxt } = Funciones;
  const { sidenavColor, login, account, nft } = controller;
  const { BMNft, BNft, ATCoin, DatUs, TNFT } = Fetchs;
  const [monto, setMonto] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [movimientos, setMovimientos] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const [pago, setPagar] = useState(false);
  const [camara, setCamara] = useState("environment");
  const [qr, setQr] = useState(false);
  const [conf, setConf] = useState(false);
  const [enviar, setEnviar] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [ticket, setTicket] = useState({
    qrcode_url: "",
    checkout_url: "",
  });
  const navigate = useNavigate();

  const recargar = () => {
    const movimientosAceptados = BMNft(nft.contract, nft.id, "100");
    movimientosAceptados
      .then((value) => {
        if (value.statusCode === undefined) setMovimientos(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });

    const movimientosPendientes = BMNft(nft.contract, nft.id, "0");
    movimientosPendientes
      .then((value) => {
        if (value.statusCode === undefined) {
          let total = 0;
          setPendientes(value);
          for (let i = 0; i < value.length; i += 1) {
            total += value[i].amountUsdt;
          }
          setTotalPendientes(total);
        } else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    if (login) {
      const walletBus = BNft(nft.contract, nft.id);
      walletBus
        .then((value) => {
          if (value.statusCode === undefined) setWallet(value[0].wallet);
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
      recargar();
    } else {
      navigate("/");
    }
  }, []);

  const Pagar = () => {
    if (monto >= 10) {
      const resultado = ATCoin(account, monto, nft.contract, nft.id);
      resultado
        .then((value) => {
          if (value.statusCode === undefined) {
            setTicket(value);
            setPagar(true);
          } else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
    } else {
      alert("El monto debe se mayor a $10 USDT.TCR20");
    }
  };

  const mostrarUsuario = (term) => {
    const usuario = DatUs(term);
    usuario
      .then((value) => {
        if (value.statusCode === undefined) setUser(value.nickname);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const buscarUsuario = (term) => {
    const usuario = DatUs(term);
    usuario
      .then((value) => {
        if (value.statusCode === undefined) {
          setId(value["_id"]);
          setName(`${value.name} ${value.lastname}`);
          setConf(true);
        } else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  const transferir = () => {
    const date = moment.utc();
    const usuario = TNFT(nft.contract, nft.id, date, id, enviar);
    usuario
      .then((value) => {
        if (value.statusCode === undefined) {
          alert("Envio realizado con exito");
          setConf(false);
          recargar();
        } else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox display="flex" flexDirection="row" sx={{ marginTop: 5 }}>
        <Avatar alt={nft.name} src={nft.url} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.id, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      <PBBox mt={2} mb={7}>
        <PBBox mt={4} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <PBBox mb={1}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Usuario (correo o nickname)
                  </InputLabel>
                  <Input
                    id="outlined-adornment-password"
                    type="text"
                    value={user}
                    fullWidth
                    onChange={(e) => setUser(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setQr(true)}
                          edge="end"
                        >
                          <CropFreeIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Usuario (correo o nickname)"
                  />
                </PBBox>
                <PBBox mb={1}>
                  <PBInput
                    type="number"
                    label="Monto a depositar en USDT"
                    variant="standard"
                    fullWidth
                    value={enviar}
                    onChange={(e) => setEnviar(e.target.value)}
                  />
                </PBBox>
                <PBButton
                  fullWidth
                  variant="gradient"
                  color="primary"
                  onClick={() => buscarUsuario(user)}
                >
                  Realizar envio
                </PBButton>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <PBBox mb={1}>
                  <PBInput
                    type="number"
                    label="Monto a depositar en USDT"
                    variant="standard"
                    fullWidth
                    value={monto}
                    onChange={(e) => setMonto(e.target.value)}
                  />
                </PBBox>
                <PBButton variant="gradient" onClick={Pagar} color="primary" fullWidth>
                  Realizar recarga en USDT
                </PBButton>
              </Card>
            </Grid>
          </Grid>
        </PBBox>
        <PBBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                color={sidenavColor}
                icon={<AttachMoneyIcon />}
                title="Saldo total"
                description="Este dinero esta en tu billetera"
                value={`$${NumSepCom(Round(wallet))} USDT`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                color={sidenavColor}
                icon={<CurrencyExchangeIcon />}
                title="Saldo en tránsito"
                description="Dinero en proceso de autorizacion"
                value={`$${NumSepCom(Round(totalPendientes))} USDT`}
              />
            </Grid>
          </Grid>
        </PBBox>
        <PBBox mb={15}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Transactions array={movimientos} tipo="completadas" mensaje="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Transactions
                array={pendientes}
                tipo="pendientes"
                mensaje="Las transacciones pueden tomar 24hr para completarse"
              />
            </Grid>
          </Grid>
        </PBBox>
      </PBBox>
      <Modal
        id="qr"
        open={conf}
        onClose={() => setConf(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card
          style={{
            width: "50%",
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <PBTypography textAlign="center">
            {`Seguro que desea enviar $${enviar} USDT a el usuario "${user}" (${name})`}
          </PBTypography>
          <PBButton variant="gradient" onClick={() => transferir()} color="primary" fullWidth>
            Aceptar
          </PBButton>
        </Card>
      </Modal>
      <Modal
        id="qr"
        open={qr}
        onClose={() => setQr(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <Card
          sx={({ breakpoints }) => ({
            width: "40%",

            [breakpoints.down("md")]: {
              width: "100%",
            },
          })}
        >
          <QrReader
            constraints={{ facingMode: camara }}
            delay={1000}
            onResult={(result) => {
              if (result !== undefined) {
                mostrarUsuario(result.text);
                setQr(false);
              }
            }}
            style={{ width: "100%" }}
          />
          <IconButton
            onClick={() => {
              if (camara === "environment") setCamara("user");
              else setCamara("environment");
            }}
          >
            <CameraswitchIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Card>
      </Modal>
      <Modal
        id="ticket-pago"
        open={pago}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <TicketCoinpayments
          ticket={ticket}
          salir={() => {
            setPagar(false);
            recargar();
          }}
        />
      </Modal>
    </DashboardLayout>
  );
}

export default Billing;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Chart } from "react-google-charts";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import Fuction from "widgets/Function";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import moment from "moment";

const StyledCard = styled.div`
  padding: 0px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  display: inline-block;
  position: relative;
  box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
`;

export default function Contracts(term) {
  const { producto, nft } = term;
  const startDate = moment.utc(producto.startDate).format("YYYY-MM-DD");
  const end = moment.utc();
  const [actual, setActual] = useState(0);
  const fecha = moment.utc(producto.startDate);
  const inicio = fecha.month(+1);
  const { Round, RTxt, NumSepCom } = Fuction;
  const [avance, setAvance] = useState(0);
  let unidades = "months";
  let diff = 0;

  useEffect(() => {
    if (producto.Settings[0].period.unit === "meses") {
      unidades = "months";
    } else if (producto.Settings[0].period.unit === "años") {
      unidades = "years";
    } else if (producto.Settings[0].period.unit === "dias") {
      unidades = "days";
    }

    diff = end.diff(startDate, unidades, true);
    if (diff < 0) diff = 0;
    setAvance(Round(diff));

    setActual(Round(producto.amount * diff));
  }, []);

  const card = (props) => (
    <StyledCard variant="button">
      <PBBox
        py={1}
        px={1}
        variant="gradient"
        style={{
          borderRadius: "10px",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            fontWeight: "bold",
            textAlign: "left",
            textTransform: "uppercase",
          }}
        >
          <b>Detalles de comisiones</b>
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Id:</b> {props.id}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Tipo de comisión:</b> {props.commissions.identifier}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Producto:</b> {props.financialProduct}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Fecha de solicitud:</b> {moment.utc(props.creationDate).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Fecha de aprovación:</b> {moment.utc(props.approvalDate).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Total a cobrar:</b> {`$${NumSepCom(props.total)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Cobro mensual:</b> {`$${NumSepCom(props.amount)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Comisiones acumuladas:</b> {`$${NumSepCom(actual)} USDT`}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Plazo:</b> {props.settings.period.amount}&nbsp;{props.settings.period.unit}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Fecha de inicio:</b> {moment.utc(inicio).format("YYYY-MM-DD")}
        </PBTypography>
        <PBTypography
          color="grey"
          style={{
            fontSize: 15,
            textAlign: "left",
          }}
        >
          <b>Fecha final:</b> {moment.utc(props.endDate).format("YYYY-MM-DD")}
        </PBTypography>
      </PBBox>
    </StyledCard>
  );

  return (
    <PBBox>
      <PBBox display="flex" flexDirection="row">
        <Avatar alt={nft.name} src={nft.image} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.nftId, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      <Grid container spacing={1} sx={{ marginTop: "0.2px" }}>
        <Grid item xs={12} sm={6}>
          <Chart
            chartType="PieChart"
            width="100%"
            data={[
              ["Rendimiento", "Monto"],
              [
                `Total: ${producto.Settings[0].period.amount} ${producto.Settings[0].period.unit}`,
                producto.Settings[0].period.amount,
              ],
              [`Avance: ${avance} ${producto.Settings[0].period.unit}`, avance],
            ]}
            options={{
              title: "Avance en meses",
              pieHole: 0.5,
              slices: {
                0: { color: "#1A1833" },
                1: { color: "#684191" },
              },
              chartArea: {
                left: 10,
                top: 40,
                width: "100%",
                height: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            {card({
              id: producto["_id"],
              amount: producto.amount,
              total: producto.total,
              financialProduct: producto.financialProduct,
              creationDate: producto.creationDate,
              approvalDate: producto.approvalDate,
              startDate: producto.startDate,
              endDate: producto.endDate,
              settings: producto.Settings[0],
              commissions: producto.Commissions[0],
            })}
          </Card>
        </Grid>
      </Grid>
    </PBBox>
  );
}

/* *******************************************************
 Funciones para representacion de cantidades
******************************************************* */

// Función que separa miles con comas devuelve un string
function NumeroSepararComas(costo) {
  const str = costo.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}

// Expresiones de lectura
module.exports = {
  // Funciones para representacion de cantidades
  NumSepCom: NumeroSepararComas, // Función que separa miles con comas devuelve un string
};

/* *******************************************************
 Funciones para tratamiento de cadena de caracteres
******************************************************* */

// Función para permitir solo letras minusculas y numeros
function SoloMinusculasNumeros(string, tamaño) {
  // solo letras y numeros
  if (string.length <= parseInt(tamaño, 10)) {
    let out = "";
    const filtro = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz1234567890"; // Caracteres admintidos

    for (let i = 0; i < string.length; i += 1)
      if (filtro.indexOf(string.charAt(i)) !== -1) out += string.charAt(i);
    return out;
  }
  return string.substr(0, parseInt(tamaño, 10));
}

function RecortarCadena(txt, i, f) {
  if (txt.length > 10) {
    const init = txt.substr(0, i);
    const fin = txt.substr(-f, f);
    return `${init}...${fin}`;
  }
  return txt;
}

/* *******************************************************
 Funciones para representacion de cantidades
******************************************************* */

// Función que separa miles con comas devuelve un string
function NumeroSepararComas(costo) {
  const str = costo.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}

function round(num) {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

// Expresiones de lectura
export default {
  // Funciones para representacion de cantidades
  NumSepCom: NumeroSepararComas, // Función que separa miles con comas devuelve un string
  RTxt: RecortarCadena, // Recortar caracteres
  // Funciones para tratamiento de cadena de caracteres
  SoloMinNum: SoloMinusculasNumeros, // Función para permitir solo letras minusculas y numeros
  Round: round,
};

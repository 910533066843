// Powerups Components React layouts
import Registrar from "layouts/register";
import Clientes from "layouts/customersBranch";
import Rutas from "routes";
import Vender from "layouts/sellUsdt";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  { type: "divider", title: "Sucursal", key: "divider-sucursal" },
  { type: "title", title: "Sucursal", key: "sucursal-pages" },
  {
    type: "collapse",
    name: "Vender USDT",
    key: "vender-usdt",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/vender-usdt",
    component: <Vender />,
  },
  {
    type: "collapse",
    name: "Registrar",
    key: "registrar",
    icon: <Icon fontSize="small">person_add_alt1</Icon>,
    route: "/registrar",
    component: <Registrar />,
  },
  {
    type: "collapse",
    name: "Afiliados",
    key: "afiliados",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/afiliados",
    component: <Clientes />,
  },
];

export default Rutas.concat(routes);

/*
  Entradas 
    unit = unidades de tiempo de contrato
    CI = capital inicial
    i = tasa de interes
    n = numero de meses
    pc = porcentaje de comision por manejo de cuenta
    ti = tipo de interes
    startDate = fecha de inicio
    end = fecha final

  Salidas
    VF = valor futuro
    st = dividendos totales
    acumulado = dividentos acumulados
    cm = total de comiciones
    t = total por cobrar
*/

export default function calcularInteres(unit, CI, i, n, pc, ti, startDate, end) {
  let diff = 0; // avance de tiempo de contrato
  switch (unit) {
    case "meses":
      diff = end.diff(startDate, "months", true);
      break;
    case "años":
      diff = end.diff(startDate, "years", true);
      break;
    case "dias":
      diff = end.diff(startDate, "days", true);
      break;
    default:
      diff = 0;
  }
  diff = Math.max(diff, 0);
  let VF = 0; // valor futuro
  let st = 0; // dividendos totales
  let acumulado = 0; // dividentos acumulados
  if (ti === "1") {
    // cálculo de interés simple
    st = CI * i * n;
    VF = st + CI;
    acumulado = diff < n ? CI * i * diff : st;
  } else {
    // cálculo de interés compuesto
    // Valor futuro (VF) = Capital inicial (CI) × (1 + tasa de interés (i))^n
    VF = CI * (1 + i) ** n;
    st = VF - CI;
    acumulado = diff < n ? CI * (1 + i) ** diff - CI : st;
  }
  const cm = VF * pc; // total de comiciones
  const t = VF - cm; // total por cobrar
  return { acumulado, st, t, cm, diff };
}

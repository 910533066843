// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import moment from "moment";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBBadge from "components/PBBadge";

// Powerups React widgets components
import Details from "layouts/detailsComissions";

// Powerups React widgets components
import DataTable from "widgets/Tables/DataTable";
import Fetchs from "widgets/Fetchs";
import Fuction from "widgets/Function";
import manejoErrores from "generales/errores";

// Powerups Components React context
import { useMaterialUIController } from "context";

const columns = [
  { Header: "Contrato", accessor: "contract", width: "10%", align: "left" },
  { Header: "NFT", accessor: "nftId", width: "15%", align: "left" },
  { Header: "Ultima operación", accessor: "lastoperation", width: "15%", align: "left" },
  { Header: "Estado", accessor: "status", width: "15%", align: "left" },
];

function Tables() {
  const [rows, setRows] = React.useState([]);
  const [controller] = useMaterialUIController();
  const [notificacion, setNotificacion] = React.useState(false);
  const { sidenavColor, nft } = controller;
  const { contract, id } = nft;
  const { BCNFT } = Fetchs;
  const { RTxt } = Fuction;

  const Tabla = (value) => {
    const array = [];
    value.forEach((e) => {
      const date = moment.utc(e["lastoperation"]).format("YYYY-MM-DD");
      let dateString = String(date);
      if (dateString === "2000-01-01") dateString = "Sin operaciones";
      let colores = { color: "warning", texto: "Inactivo" };
      if (e.status) colores = { color: "success", texto: "Activo" };
      array.push({
        id: e["_id"],
        contract: RTxt(e["contract"], 5, 5),
        nftId: RTxt(e["nftId"], 4, 4),
        lastoperation: dateString,
        status: (
          <PBBox ml={-1}>
            <PBBadge
              badgeContent={colores.texto}
              color={colores.color}
              variant="gradient"
              size="md"
            />
          </PBBox>
        ),
      });
    });
    setRows(array);
  };

  const Buscar = () => {
    const resultado = BCNFT(contract, id);
    resultado
      .then((value) => {
        if (value.statusCode === undefined) Tabla(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    Buscar();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox display="flex" flexDirection="row" sx={{ marginTop: 5 }}>
        <Avatar alt={nft.name} src={nft.url} variant="rounded" sx={{ width: 150, height: 150 }} />
        <PBBox>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            {nft.name}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            ID TOKEN: {RTxt(nft.id, 4, 4)}
          </PBTypography>
          <PBTypography
            display="flex"
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            color="dark"
          >
            RED: {nft.blockchain}
          </PBTypography>
        </PBBox>
      </PBBox>
      <PBBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <PBBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <PBTypography variant="h6" color="white">
                  Cuentahabientes
                </PBTypography>
              </PBBox>
              <PBBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </PBBox>
            </Card>
          </Grid>
        </Grid>

        <Modal
          id="Comprobacion.inversion"
          open={notificacion}
          onClose={() => setNotificacion(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Card
            id="contenedor"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: "60%",
            }}
          >
            <Details />
          </Card>
        </Modal>
      </PBBox>
    </DashboardLayout>
  );
}

export default Tables;

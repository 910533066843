// React
import React from "react";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import { Typography } from "@mui/material";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";

function Cover({ usuario }) {
  const { ANft, BNft } = Fetchs;
  const [numero, setNumero] = React.useState("");

  const Agregar = async () => {
    try {
      const resultado = ANft(usuario["_id"], numero);
      await resultado
        .then((value) => {
          console.log(value);
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
    } catch (error) {
      console.log(error);
      alert(manejoErrores(error));
    }
  };

  const Verificar = async () => {
    try {
      const resultado = BNft("GOLD", numero);
      await resultado
        .then((value) => {
          console.log(value);
          if (value.length > 0) alert("NFT de ya asignado");
          else Agregar();
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
    } catch (error) {
      console.error(error);
      alert(manejoErrores(error));
    }
  };

  return (
    <PBBox m={0} p={0}>
      <PBBox component="form" role="form">
        <Typography>Solo se pueden agregar NFTs GOLD</Typography>
        {/* Numero de NFT */}
        <PBBox mb={0}>
          <PBInput
            type="number"
            label="Numero de NFT"
            variant="standard"
            fullWidth
            onChange={(e) => setNumero(e.target.value)}
          />
        </PBBox>

        <PBBox mt={4} mb={0}>
          <PBButton variant="gradient" color="primary" fullWidth onClick={() => Verificar()}>
            Registrar
          </PBButton>
        </PBBox>
      </PBBox>
    </PBBox>
  );
}

export default Cover;

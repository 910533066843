// Powerups Components React layouts
import DataSucursales from "layouts/admins/sucursales/table";
import DataUsers from "layouts/admins/users";
import DataContratos from "layouts/admins/productos/table";
import DataNFT from "layouts/admins/nft/table";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  { type: "divider", title: "Administración", key: "divider-admin-pages" },
  { type: "title", title: "Administración", key: "admin-pages" },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/usuarios",
    component: <DataUsers />,
  },
  {
    type: "collapse",
    name: "Contratos",
    key: "contratos",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/contratos",
    component: <DataContratos />,
  },
  {
    type: "collapse",
    name: "Sucursales",
    key: "sucursales",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/sucursales",
    component: <DataSucursales />,
  },
  {
    type: "collapse",
    name: "Nft's",
    key: "nfts",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/nfts",
    component: <DataNFT />,
  },
];

export default routes;

import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

// Powerups Components React context
import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";

// react-router components
import { useNavigate } from "react-router-dom";

// ICONS
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

// Powerups React components
import PBBox from "components/PBBox";

// Powerups Components React examples
import DefaultInfoCard from "widgets/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Transactions from "layouts/billing/components/Transactions";
import Funciones from "widgets/Function";
import Fetchs from "widgets/Fetchs";
import manejoErrores from "generales/errores";

function Billing({ account }) {
  const [controller] = useMaterialUIController();
  const { NumSepCom, Round } = Funciones;
  const { sidenavColor, login } = controller;
  const { BMUse, DatUs } = Fetchs;
  const [wallet, setWallet] = useState(0);
  const [movimientos, setMovimientos] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const navigate = useNavigate();

  const recargar = () => {
    const movimientosAceptados = BMUse(account, "100");
    movimientosAceptados
      .then((value) => {
        if (value.statusCode === undefined) setMovimientos(value);
        else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });

    const movimientosPendientes = BMUse(account, "0");
    movimientosPendientes
      .then((value) => {
        if (value.statusCode === undefined) {
          let total = 0;
          setPendientes(value);
          for (let i = 0; i < value.length; i += 1) {
            total += value[i].amountUsdt;
          }
          setTotalPendientes(total);
        } else alert(manejoErrores(value));
      })
      .catch((error) => {
        alert(manejoErrores(error));
      });
  };

  useEffect(() => {
    if (login) {
      const walletBus = DatUs(account);
      walletBus
        .then((value) => {
          if (value.statusCode === undefined) setWallet(value.wallet);
          else alert(manejoErrores(value));
        })
        .catch((error) => {
          alert(manejoErrores(error));
        });
      recargar();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <PBBox mt={2} mb={7}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <center>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "90%", width: "90%" }}
              value={account}
              viewBox="0 0 256 256"
            />
          </center>
        </Grid>
        <Grid item xs={12} md={9} />
      </Grid>
      <PBBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DefaultInfoCard
              color={sidenavColor}
              icon={<AttachMoneyIcon />}
              title="Saldo total"
              description="Este dinero esta en tu billetera"
              value={`$${NumSepCom(Round(wallet))} USDT`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultInfoCard
              color={sidenavColor}
              icon={<CurrencyExchangeIcon />}
              title="Saldo en tránsito"
              description="Dinero en proceso de autorizacion"
              value={`$${NumSepCom(Round(totalPendientes))} USDT`}
            />
          </Grid>
        </Grid>
      </PBBox>
      <PBBox mb={15}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Transactions array={movimientos} tipo="completadas" mensaje="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Transactions
              array={pendientes}
              tipo="pendientes"
              mensaje="Las transacciones pueden tomar 24hr para completarse"
            />
          </Grid>
        </Grid>
      </PBBox>
    </PBBox>
  );
}

export default Billing;

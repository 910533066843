import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import ArticleIcon from "@mui/icons-material/Article";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import GroupIcon from "@mui/icons-material/Group";

// Powerups React components
import PBBox from "components/PBBox";
import PBButton from "components/PBButton";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import DataSucursales from "./sucursales/table";
import DataUsers from "./users";
import DataContratos from "./productos/table";
import DataNFT from "./nft/table";

function Dashboard() {
  const [state, setState] = useState("");
  const [pantalla, setPantalla] = useState();

  const setPantallas = async (estado) => {
    if (state !== estado) {
      switch (estado) {
        case "usuarios":
          setPantalla(<DataUsers />);
          break;
        case "contratos":
          setPantalla(<DataContratos />);
          break;
        case "sucursales":
          setPantalla(<DataSucursales />);
          break;
        case "nfts":
          setPantalla(<DataNFT />);
          break;
        default:
          break;
      }
    }
    setState(estado);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox py={3} style={{ height: "90%" }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <PBBox mb={1.5}>
              <PBButton
                fullWidth
                variant="gradient"
                color="primary"
                startIcon={<GroupIcon />}
                sx={{
                  margin: 0,
                  padding: 1,
                }}
                onClick={() => setPantallas("usuarios")}
              >
                Usuarios
              </PBButton>
            </PBBox>
          </Grid>
          <Grid item xs={3}>
            <PBBox mb={1.5}>
              <PBButton
                fullWidth
                variant="gradient"
                color="primary"
                startIcon={<ArticleIcon />}
                sx={{
                  margin: 0,
                  padding: 1,
                }}
                onClick={() => setPantallas("contratos")}
              >
                Contratos
              </PBButton>
            </PBBox>
          </Grid>
          <Grid item xs={3}>
            <PBBox mb={1.5}>
              <PBButton
                fullWidth
                variant="gradient"
                color="primary"
                startIcon={<AddBusinessIcon />}
                sx={{
                  margin: 0,
                  padding: 1,
                }}
                onClick={() => {
                  setPantallas("sucursales");
                }}
              >
                Sucursales
              </PBButton>
            </PBBox>
          </Grid>
          <Grid item xs={3}>
            <PBBox mb={1.5}>
              <PBButton
                fullWidth
                variant="gradient"
                color="primary"
                startIcon={<AddBusinessIcon />}
                sx={{
                  margin: 0,
                  padding: 1,
                }}
                onClick={() => {
                  setPantallas("nfts");
                }}
              >
                Nft&apos;s
              </PBButton>
            </PBBox>
          </Grid>
          <Grid item xs={12}>
            {pantalla}
          </Grid>
        </Grid>
      </PBBox>
    </DashboardLayout>
  );
}

export default Dashboard;

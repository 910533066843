// Powerups Components React layouts
import Escritorio from "layouts/dashboard";
import Billetera from "layouts/billing";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Escritorio",
    key: "escritorio",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/escritorio",
    component: <Escritorio />,
  },
  {
    type: "collapse",
    name: "Billetera",
    key: "billetera",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/billetera",
    component: <Billetera />,
  },
];

export default routes;

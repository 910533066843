import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

// Powerups React components
import PBBox from "components/PBBox";
import PBTypography from "components/PBTypography";
import PBButton from "components/PBButton";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Modal from "@mui/material/Modal";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";
import manejoErrores from "generales/errores";

const StyledCard = styled.div`
  padding: 0px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  display: inline-block;
  position: relative;
  box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
`;

function School() {
  const [videos, setVideos] = useState([]);
  const [ver, setVer] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(async () => {
    try {
      const consulta = await fetch(`https://edbit.online/files_support_php/tutoriales.php`, {
        method: "GET",
      });
      const resultado = await consulta.json();
      setVideos(resultado);
    } catch (error) {
      alert(manejoErrores(error));
    }
  }, []);

  const card = (props) => (
    <StyledCard variant="button">
      <PBBox
        py={1}
        px={1}
        variant="gradient"
        style={{
          borderRadius: "10px",
          textAlign: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Avatar
          alt={props.title}
          src={props.imagen}
          variant="rounded"
          sx={{ width: "100%", height: "100%" }}
        />
        <PBTypography
          color="grey"
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {props.title}
        </PBTypography>
        <PBButton
          variant="gradient"
          color="primary"
          fullWidth
          style={{
            marginTop: 10,
          }}
          onClick={() => {
            setVer(true);
            setUrl(props.url);
          }}
        >
          ver tutorial
        </PBButton>
      </PBBox>
    </StyledCard>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox>
        {videos.map((row) => (
          <PBBox>
            <PBTypography
              display="flex"
              mt={6}
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                marginLeft: 10,
                textTransform: "uppercase",
              }}
              color="dark"
            >
              {row.title}
            </PBTypography>
            <Grid container spacing={2} mt={0} mb={2} style={{ width: "100%" }}>
              {row.list.map((video) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  key={row["_id"]}
                  style={{ width: "100%", height: "100%" }}
                >
                  <PBBox>
                    <Card variant="outlined">
                      {card({
                        imagen: video.imagen,
                        title: video.title,
                        url: video.url,
                      })}
                    </Card>
                  </PBBox>
                </Grid>
              ))}
            </Grid>
          </PBBox>
        ))}
      </PBBox>

      <Modal
        id="Videos"
        open={ver}
        onClose={() => setVer(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "purple",
        }}
      >
        <div style={{ width: "80%", height: "80%" }}>
          <ReactPlayer url={url} playing controls width="100%" height="100%" />
        </div>
      </Modal>
    </DashboardLayout>
  );
}

export default School;

// React
import React from "react";

// Powerups React widgets components
import DashboardLayout from "widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "widgets/Navbars/DashboardNavbar";

// @mui material components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Powerups React components
import PBBox from "components/PBBox";
import PBInput from "components/PBInput";
import PBButton from "components/PBButton";
import Fetchs from "widgets/Fetchs";
import FunGenerales from "widgets/Function";
import Codigos from "generales/codigos";
import Cuentas from "generales/cuentas";

// Images, logos, icons
import { FcInfo } from "react-icons/fc";

// Powerups Components React contexts
import { useMaterialUIController } from "context";

const datosEmpresa = () => {
  const [controller] = useMaterialUIController();
  const { account } = controller;
  const [usuario, setUsuario] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [lada, setLada] = React.useState(Codigos[151]);
  const [cuenta, setCuenta] = React.useState(Cuentas[0]);
  const [telefono, setTelefono] = React.useState(0);
  const [comentario, setComentario] = React.useState("");
  const { InserUsP, InsPR } = Fetchs;
  const { SoloMinNum } = FunGenerales;

  const InsertarPago = (id) => {
    const resultado = InsPR(account.idUsuario, usuario, cuenta.valor, id, cuenta.tipo);
    resultado
      .then((value) => {
        if (value.status) {
          alert("Agregado correctamente");
        } else {
          alert(value.mensaje);
        }
      })
      .catch();
  };

  const InsertarUsuario = () => {
    const nombreCompleto = `${nombre} ${apellido}`;
    const ladaCompleto = `${lada.pais}|${lada.codigo}`;
    const resultado = InserUsP(
      usuario,
      nombreCompleto,
      ladaCompleto,
      telefono,
      correo,
      1,
      comentario,
      account.idUsuario
    );
    resultado
      .then((value) => {
        if (value.status) {
          InsertarPago(value.mensaje);
        } else {
          alert(value.mensaje);
        }
      })
      .catch();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PBBox id="arriba">
        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Nickname"
            variant="standard"
            fullWidth
            value={usuario}
            onChange={(e) => setUsuario(SoloMinNum(e.target.value, 15))}
          >
            <Tooltip
              style={{ fontSize: 5 }}
              title="Solo se permiten letras y números, con un máximo de 20 caracteres."
            >
              <IconButton>
                <FcInfo size={15} m={0} p={0} />
              </IconButton>
            </Tooltip>
          </PBInput>
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Nombre"
            variant="standard"
            fullWidth
            onChange={(e) => setNombre(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Apellido"
            variant="standard"
            fullWidth
            onChange={(e) => setApellido(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <Autocomplete
            id="combo-box-demo"
            value={lada}
            onChange={(event, newValue) => {
              setLada(newValue);
            }}
            options={Codigos}
            getOptionLabel={(option) => `${option.pais} (${option.codigo})`}
            renderInput={(params) => <TextField {...params} label="Pais" variant="standard" />}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="number"
            label="Teléfono"
            variant="standard"
            fullWidth
            onChange={(e) => setTelefono(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="email"
            label="Email"
            variant="standard"
            fullWidth
            onChange={(e) => setCorreo(e.target.value)}
          />
        </PBBox>

        <PBBox mb={1}>
          <Autocomplete
            id="combo-box-demo"
            value={cuenta}
            onChange={(event, newValue) => {
              setCuenta(newValue);
            }}
            options={Cuentas}
            getOptionLabel={(option) => `${option.cuenta} ($${option.valor} USDT)`}
            renderInput={(params) => <TextField {...params} label="Cuenta" variant="standard" />}
          />
        </PBBox>

        <PBBox mb={1}>
          <PBInput
            type="text"
            label="Comentarios"
            rows={1}
            variant="standard"
            multiline
            fullWidth
            onChange={(e) => setComentario(e.target.value)}
          />
        </PBBox>

        <PBBox mt={4} mb={1}>
          <PBButton variant="gradient" color="primary" fullWidth onClick={() => InsertarUsuario()}>
            Registrar
          </PBButton>
        </PBBox>
      </PBBox>
    </DashboardLayout>
  );
};

export default datosEmpresa;
